// notificationUtils.js

const notifications = [
    "Manoj from Patna just ordered the Personalized Love Song Album",
    "Kabir from Vadodara just ordered the Slideshow video song",
    "Neha from Bangalore just ordered the Slideshow video song",
    "Sneha from Delhi just ordered the Slideshow video song",
    "Rohan from Bangalore just ordered the Personalized Love Song Album",
    "Arjun from Patna just ordered the Personalized Love Song Album",
    "Ankit from Jaipur just ordered the Slideshow video song",
    "Anjali from Ludhiana just ordered the Personalized Love Song Album",
    "Samantha from Indore just ordered the Slideshow video song",
    "Samantha from Ahmedabad just ordered the Personalized Love Song Album",
    "Simran from Surat just ordered the Personalized Love Song Album",
    "Sneha from Kolkata just ordered the Personalized Love Song Album",
    "Neha from Delhi just ordered the Personalized Love Song Album",
    "Pooja from Bhopal just ordered the Personalized Love Song Album",
    "Abhishek from Hyderabad just ordered the Slideshow video song",
    "Ankit from Bhopal just ordered the Slideshow video song",
    "Aditi from Patna just ordered the Slideshow video song",
    "Sneha from Bhopal just ordered the Slideshow video song",
    "Arjun from Ludhiana just ordered the Personalized Love Song Album",
    "Neha from Kanpur just ordered the Personalized Love Song Album",
    "Nisha from Agra just ordered the Slideshow video song",
    "Rohan from Patna just ordered the Slideshow video song",
    "Aditi from Jaipur just ordered the Personalized Love Song Album",
    "Rohan from Bangalore just ordered the Slideshow video song",
    "Rohan from Kanpur just ordered the Slideshow video song",
    "Sneha from Hyderabad just ordered the Slideshow video song",
    "Neha from Kolkata just ordered the Slideshow video song",
    "Manoj from Kanpur just ordered the Personalized Love Song Album",
    "Karan from Patna just ordered the Personalized Love Song Album",
    "Samantha from Chennai just ordered the Slideshow video song",
    "Vikram from Patna just ordered the Personalized Love Song Album",
    "Manoj from Nashik just ordered the Slideshow video song",
    "Vikram from Lucknow just ordered the Slideshow video song",
    "Kabir from Bangalore just ordered the Slideshow video song",
    "Rahul from Kanpur just ordered the Personalized Love Song Album",
    "Nisha from Vadodara just ordered the Slideshow video song",
    "Arjun from Bangalore just ordered the Personalized Love Song Album",
    "Pooja from Jaipur just ordered the Personalized Love Song Album",
    "Abhishek from Chennai just ordered the Slideshow video song",
    "Vikram from Bhopal just ordered the Personalized Love Song Album",
    "Riya from Bhopal just ordered the Slideshow video song",
    "Priya from Vadodara just ordered the Personalized Love Song Album",
    "Manoj from Patna just ordered the Personalized Love Song Album",
    "Ankit from Mumbai just ordered the Slideshow video song",
    "Aditi from Jaipur just ordered the Slideshow video song",
    "Arjun from Lucknow just ordered the Slideshow video song",
    "Manoj from Chennai just ordered the Slideshow video song",
    "Aditi from Hyderabad just ordered the Slideshow video song",
    "Anjali from Patna just ordered the Slideshow video song",
    "Riya from Agra just ordered the Personalized Love Song Album",
    "Priya from Agra just ordered the Slideshow video song",
    "Rahul from Pune just ordered the Personalized Love Song Album",
    "Nisha from Surat just ordered the Slideshow video song",
    "Samantha from Agra just ordered the Slideshow video song",
    "Kabir from Kanpur just ordered the Slideshow video song",
    "Priya from Patna just ordered the Slideshow video song",
    "Neha from Patna just ordered the Personalized Love Song Album",
    "Arjun from Nagpur just ordered the Personalized Love Song Album",
    "Priya from Delhi just ordered the Slideshow video song",
    "Sneha from Chennai just ordered the Personalized Love Song Album",
    "Priya from Delhi just ordered the Personalized Love Song Album",
    "Arjun from Pune just ordered the Slideshow video song",
    "Raj from Vadodara just ordered the Personalized Love Song Album",
    "Riya from Nagpur just ordered the Personalized Love Song Album",
    "Vikram from Agra just ordered the Personalized Love Song Album",
    "Vikram from Vadodara just ordered the Slideshow video song",
    "Nisha from Patna just ordered the Personalized Love Song Album",
    "Arjun from Bangalore just ordered the Personalized Love Song Album",
    "Vikram from Lucknow just ordered the Slideshow video song",
    "Neha from Surat just ordered the Personalized Love Song Album",
    "Pooja from Kolkata just ordered the Slideshow video song",
    "Priya from Pune just ordered the Personalized Love Song Album",
    "Arjun from Chennai just ordered the Personalized Love Song Album",
    "Vikram from Ahmedabad just ordered the Personalized Love Song Album",
    "Abhishek from Ahmedabad just ordered the Personalized Love Song Album",
    "Manoj from Mumbai just ordered the Slideshow video song",
    "Kabir from Vadodara just ordered the Slideshow video song",
    "Riya from Agra just ordered the Slideshow video song",
    "Anjali from Bhopal just ordered the Slideshow video song",
    "Abhishek from Chennai just ordered the Slideshow video song",
    "Pooja from Bhopal just ordered the Personalized Love Song Album",
    "Anjali from Ludhiana just ordered the Slideshow video song",
    "Manoj from Surat just ordered the Slideshow video song",
    "Rohan from Delhi just ordered the Personalized Love Song Album",
    "Abhishek from Agra just ordered the Slideshow video song",
    "Raj from Bhopal just ordered the Slideshow video song",
    "Samantha from Ludhiana just ordered the Personalized Love Song Album",
    "Neha from Delhi just ordered the Slideshow video song",
    "Rohan from Mumbai just ordered the Personalized Love Song Album",
    "Pooja from Indore just ordered the Slideshow video song",
    "Ankit from Delhi just ordered the Slideshow video song",
    "Vikram from Pune just ordered the Slideshow video song",
    "Samantha from Indore just ordered the Personalized Love Song Album",
    "Rohan from Kanpur just ordered the Slideshow video song",
    "Anjali from Indore just ordered the Slideshow video song",
    "Sneha from Chennai just ordered the Personalized Love Song Album",
    "Priya from Nashik just ordered the Slideshow video song",
    "Abhishek from Bangalore just ordered the Slideshow video song",
    "Rohan from Kanpur just ordered the Slideshow video song",
    "Rahul from Lucknow just ordered the Personalized Love Song Album",
    "Ankit from Jaipur just ordered the Personalized Love Song Album",
    "Ankit from Bhopal just ordered the Slideshow video song",
    "Aditi from Surat just ordered the Slideshow video song",
    "Anjali from Pune just ordered the Slideshow video song",
    "Ankit from Patna just ordered the Slideshow video song",
    "Karan from Patna just ordered the Personalized Love Song Album",
    "Rahul from Bhopal just ordered the Slideshow video song",
    "Vikram from Ahmedabad just ordered the Slideshow video song",
    "Rahul from Ludhiana just ordered the Slideshow video song",
    "Rahul from Kolkata just ordered the Slideshow video song",
    "Aditi from Vadodara just ordered the Slideshow video song",
    "Riya from Bhopal just ordered the Personalized Love Song Album",
    "Priya from Mumbai just ordered the Slideshow video song",
    "Kabir from Hyderabad just ordered the Personalized Love Song Album",
    "Nisha from Kanpur just ordered the Personalized Love Song Album",
    "Aditi from Kanpur just ordered the Slideshow video song",
    "Ankit from Agra just ordered the Slideshow video song",
    "Karan from Kanpur just ordered the Personalized Love Song Album",
    "Sneha from Patna just ordered the Slideshow video song",
    "Raj from Kolkata just ordered the Personalized Love Song Album",
    "Abhishek from Lucknow just ordered the Slideshow video song",
    "Rahul from Pune just ordered the Personalized Love Song Album",
    "Rahul from Agra just ordered the Personalized Love Song Album",
    "Abhishek from Hyderabad just ordered the Personalized Love Song Album",
    "Simran from Patna just ordered the Personalized Love Song Album",
    "Arjun from Surat just ordered the Slideshow video song",
    "Abhishek from Jaipur just ordered the Personalized Love Song Album",
    "Arjun from Agra just ordered the Personalized Love Song Album",
    "Ankit from Surat just ordered the Personalized Love Song Album",
    "Rohan from Hyderabad just ordered the Personalized Love Song Album",
    "Abhishek from Kanpur just ordered the Slideshow video song",
    "Karan from Bangalore just ordered the Personalized Love Song Album",
    "Nisha from Surat just ordered the Slideshow video song",
    "Ankit from Indore just ordered the Personalized Love Song Album",
    "Rahul from Bhopal just ordered the Slideshow video song",
    "Pooja from Pune just ordered the Slideshow video song",
    "Aditi from Nashik just ordered the Slideshow video song",
    "Karan from Vadodara just ordered the Personalized Love Song Album",
    "Priya from Jaipur just ordered the Slideshow video song",
    "Arjun from Patna just ordered the Slideshow video song",
    "Anjali from Nashik just ordered the Slideshow video song",
    "Rahul from Ahmedabad just ordered the Personalized Love Song Album",
    "Pooja from Agra just ordered the Personalized Love Song Album",
    "Samantha from Lucknow just ordered the Personalized Love Song Album",
    "Raj from Surat just ordered the Slideshow video song",
    "Manoj from Chennai just ordered the Slideshow video song",
    "Neha from Delhi just ordered the Personalized Love Song Album",
    "Abhishek from Vadodara just ordered the Personalized Love Song Album",
    "Priya from Delhi just ordered the Personalized Love Song Album",
    "Karan from Jaipur just ordered the Slideshow video song",
    "Manoj from Patna just ordered the Personalized Love Song Album",
    "Riya from Ahmedabad just ordered the Personalized Love Song Album",
    "Aditi from Kanpur just ordered the Slideshow video song",
    "Rahul from Indore just ordered the Personalized Love Song Album",
    "Priya from Jaipur just ordered the Slideshow video song",
    "Anjali from Chennai just ordered the Slideshow video song",
    "Raj from Indore just ordered the Personalized Love Song Album",
    "Sneha from Indore just ordered the Slideshow video song",
    "Pooja from Surat just ordered the Personalized Love Song Album",
    "Kabir from Nagpur just ordered the Slideshow video song",
    "Arjun from Kolkata just ordered the Personalized Love Song Album",
    "Anjali from Mumbai just ordered the Personalized Love Song Album",
    "Samantha from Nagpur just ordered the Slideshow video song",
    "Aditi from Nashik just ordered the Slideshow video song",
    "Raj from Patna just ordered the Personalized Love Song Album",
    "Sneha from Bhopal just ordered the Personalized Love Song Album",
    "Samantha from Hyderabad just ordered the Personalized Love Song Album",
    "Raj from Kanpur just ordered the Personalized Love Song Album",
    "Neha from Ahmedabad just ordered the Slideshow video song",
    "Nisha from Ludhiana just ordered the Slideshow video song",
    "Raj from Kolkata just ordered the Personalized Love Song Album",
    "Aditi from Mumbai just ordered the Personalized Love Song Album",
    "Karan from Ludhiana just ordered the Personalized Love Song Album",
    "Neha from Bangalore just ordered the Personalized Love Song Album",
    "Sneha from Bhopal just ordered the Slideshow video song",
    "Samantha from Agra just ordered the Slideshow video song",
    "Rahul from Vadodara just ordered the Personalized Love Song Album",
    "Karan from Surat just ordered the Personalized Love Song Album",
    "Rohan from Pune just ordered the Personalized Love Song Album",
    "Arjun from Delhi just ordered the Slideshow video song",
    "Rohan from Delhi just ordered the Personalized Love Song Album",
    "Aditi from Nashik just ordered the Slideshow video song",
    "Priya from Nashik just ordered the Personalized Love Song Album",
    "Manoj from Nagpur just ordered the Personalized Love Song Album",
    "Neha from Nagpur just ordered the Personalized Love Song Album",
    "Samantha from Kanpur just ordered the Personalized Love Song Album",
    "Ankit from Chennai just ordered the Slideshow video song",
    "Arjun from Pune just ordered the Personalized Love Song Album",
    "Arjun from Nashik just ordered the Slideshow video song",
    "Karan from Kolkata just ordered the Personalized Love Song Album",
    "Anjali from Chennai just ordered the Personalized Love Song Album",
    "Raj from Bhopal just ordered the Slideshow video song",
    "Pooja from Kanpur just ordered the Personalized Love Song Album",
    "Ankit from Hyderabad just ordered the Slideshow video song",
    "Sneha from Chennai just ordered the Slideshow video song",
    "Manoj from Delhi just ordered the Slideshow video song",
    "Raj from Jaipur just ordered the Personalized Love Song Album",
    "Riya from Kanpur just ordered the Personalized Love Song Album",
    "Nisha from Hyderabad just ordered the Slideshow video song",
    "Riya from Chennai just ordered the Personalized Love Song Album",
    "Priya from Bhopal just ordered the Personalized Love Song Album",
    "Riya from Bangalore just ordered the Slideshow video song",
    "Nisha from Nagpur just ordered the Personalized Love Song Album",
    "Rohan from Agra just ordered the Personalized Love Song Album",
    "Ankit from Kanpur just ordered the Slideshow video song",
    "Priya from Pune just ordered the Personalized Love Song Album",
    "Aditi from Mumbai just ordered the Personalized Love Song Album",
    "Simran from Surat just ordered the Personalized Love Song Album",
    "Pooja from Delhi just ordered the Slideshow video song",
    "Riya from Agra just ordered the Slideshow video song",
    "Neha from Delhi just ordered the Slideshow video song",
    "Karan from Kanpur just ordered the Slideshow video song",
    "Anjali from Mumbai just ordered the Personalized Love Song Album",
    "Neha from Bangalore just ordered the Personalized Love Song Album",
    "Riya from Ahmedabad just ordered the Slideshow video song",
    "Ankit from Nashik just ordered the Personalized Love Song Album",
    "Abhishek from Ludhiana just ordered the Slideshow video song",
    "Sneha from Hyderabad just ordered the Slideshow video song",
    "Samantha from Ludhiana just ordered the Personalized Love Song Album",
    "Vikram from Bhopal just ordered the Personalized Love Song Album",
    "Aditi from Hyderabad just ordered the Personalized Love Song Album",
    "Karan from Nagpur just ordered the Personalized Love Song Album",
    "Anjali from Agra just ordered the Personalized Love Song Album",
    "Rohan from Pune just ordered the Slideshow video song",
    "Aditi from Delhi just ordered the Slideshow video song",
    "Neha from Kolkata just ordered the Personalized Love Song Album",
    "Rohan from Mumbai just ordered the Personalized Love Song Album",
    "Manoj from Ludhiana just ordered the Slideshow video song",
    "Simran from Nashik just ordered the Slideshow video song",
    "Manoj from Hyderabad just ordered the Slideshow video song",
    "Simran from Bangalore just ordered the Slideshow video song",
    "Karan from Indore just ordered the Slideshow video song",
    "Aditi from Patna just ordered the Slideshow video song",
    "Kabir from Agra just ordered the Slideshow video song",
    "Manoj from Agra just ordered the Slideshow video song",
    "Manoj from Indore just ordered the Slideshow video song",
    "Rahul from Bhopal just ordered the Slideshow video song",
    "Rohan from Nashik just ordered the Slideshow video song",
    "Anjali from Nashik just ordered the Slideshow video song",
    "Raj from Vadodara just ordered the Personalized Love Song Album",
    "Nisha from Surat just ordered the Personalized Love Song Album",
    "Anjali from Hyderabad just ordered the Slideshow video song",
    "Vikram from Chennai just ordered the Personalized Love Song Album",
    "Pooja from Nashik just ordered the Personalized Love Song Album",
    "Aditi from Mumbai just ordered the Slideshow video song",
    "Arjun from Agra just ordered the Personalized Love Song Album",
    "Nisha from Kolkata just ordered the Personalized Love Song Album",
    "Arjun from Nashik just ordered the Personalized Love Song Album",
    "Manoj from Nashik just ordered the Slideshow video song",
    "Arjun from Bhopal just ordered the Personalized Love Song Album",
    "Rohan from Patna just ordered the Slideshow video song",
    "Manoj from Hyderabad just ordered the Slideshow video song",
    "Nisha from Kolkata just ordered the Personalized Love Song Album",
    "Aditi from Ahmedabad just ordered the Personalized Love Song Album",
    "Rahul from Agra just ordered the Slideshow video song",
    "Sneha from Hyderabad just ordered the Slideshow video song",
    "Priya from Nagpur just ordered the Personalized Love Song Album",
    "Manoj from Chennai just ordered the Personalized Love Song Album",
    "Arjun from Ahmedabad just ordered the Slideshow video song",
    "Aditi from Hyderabad just ordered the Slideshow video song",
    "Aditi from Agra just ordered the Personalized Love Song Album",
    "Neha from Nagpur just ordered the Personalized Love Song Album",
    "Vikram from Jaipur just ordered the Slideshow video song",
    "Samantha from Jaipur just ordered the Slideshow video song",
    "Pooja from Nagpur just ordered the Personalized Love Song Album",
    "Manoj from Nashik just ordered the Slideshow video song",
    "Rahul from Nagpur just ordered the Slideshow video song",
    "Vikram from Nagpur just ordered the Slideshow video song",
    "Priya from Kanpur just ordered the Personalized Love Song Album",
    "Ankit from Lucknow just ordered the Personalized Love Song Album",
    "Neha from Ludhiana just ordered the Personalized Love Song Album",
    "Vikram from Nashik just ordered the Personalized Love Song Album",
    "Manoj from Indore just ordered the Personalized Love Song Album",
    "Kabir from Agra just ordered the Slideshow video song",
    "Neha from Ahmedabad just ordered the Personalized Love Song Album",
    "Ankit from Kolkata just ordered the Personalized Love Song Album",
    "Samantha from Hyderabad just ordered the Personalized Love Song Album",
    "Nisha from Delhi just ordered the Slideshow video song",
    "Rohan from Ludhiana just ordered the Personalized Love Song Album",
    "Pooja from Hyderabad just ordered the Slideshow video song",
    "Riya from Kolkata just ordered the Personalized Love Song Album",
    "Nisha from Kanpur just ordered the Slideshow video song",
    "Abhishek from Bangalore just ordered the Slideshow video song",
    "Karan from Delhi just ordered the Slideshow video song",
    "Simran from Agra just ordered the Slideshow video song",
    "Ankit from Indore just ordered the Personalized Love Song Album",
    "Abhishek from Lucknow just ordered the Personalized Love Song Album",
    "Riya from Bhopal just ordered the Personalized Love Song Album",
    "Priya from Bhopal just ordered the Slideshow video song",
    "Raj from Kanpur just ordered the Slideshow video song",
    "Abhishek from Ludhiana just ordered the Personalized Love Song Album",
    "Riya from Surat just ordered the Slideshow video song",
    "Rahul from Delhi just ordered the Slideshow video song",
    "Anjali from Nashik just ordered the Personalized Love Song Album",
    "Rohan from Ludhiana just ordered the Personalized Love Song Album",
    "Anjali from Ludhiana just ordered the Slideshow video song",
    "Anjali from Kanpur just ordered the Slideshow video song",
    "Nisha from Nagpur just ordered the Slideshow video song",
    "Nisha from Chennai just ordered the Personalized Love Song Album",
    "Abhishek from Bangalore just ordered the Slideshow video song",
    "Rahul from Ludhiana just ordered the Personalized Love Song Album",
    "Neha from Lucknow just ordered the Slideshow video song",
    "Arjun from Mumbai just ordered the Slideshow video song",
    "Aditi from Patna just ordered the Personalized Love Song Album",
    "Samantha from Kolkata just ordered the Slideshow video song",
    "Manoj from Ludhiana just ordered the Slideshow video song",
    "Nisha from Agra just ordered the Personalized Love Song Album",
    "Anjali from Lucknow just ordered the Personalized Love Song Album",
    "Aditi from Jaipur just ordered the Slideshow video song",
    "Ankit from Nagpur just ordered the Personalized Love Song Album",
    "Manoj from Nashik just ordered the Slideshow video song",
    "Neha from Chennai just ordered the Slideshow video song",
    "Sneha from Chennai just ordered the Personalized Love Song Album",
    "Simran from Nashik just ordered the Slideshow video song",
    "Karan from Bhopal just ordered the Slideshow video song",
    "Pooja from Kanpur just ordered the Slideshow video song",
    "Ankit from Bangalore just ordered the Personalized Love Song Album",
    "Nisha from Mumbai just ordered the Personalized Love Song Album",
    "Kabir from Vadodara just ordered the Slideshow video song",
    "Kabir from Kolkata just ordered the Personalized Love Song Album",
    "Simran from Ludhiana just ordered the Slideshow video song",
    "Rohan from Nagpur just ordered the Personalized Love Song Album",
    "Anjali from Vadodara just ordered the Personalized Love Song Album",
    "Samantha from Delhi just ordered the Personalized Love Song Album",
    "Aditi from Kanpur just ordered the Slideshow video song",
    "Aditi from Vadodara just ordered the Personalized Love Song Album",
    "Anjali from Delhi just ordered the Slideshow video song",
    "Karan from Pune just ordered the Personalized Love Song Album",
    "Manoj from Hyderabad just ordered the Slideshow video song",
    "Riya from Ahmedabad just ordered the Personalized Love Song Album",
    "Arjun from Kolkata just ordered the Slideshow video song",
    "Nisha from Nagpur just ordered the Personalized Love Song Album",
    "Raj from Pune just ordered the Slideshow video song",
    "Anjali from Mumbai just ordered the Slideshow video song",
    "Vikram from Chennai just ordered the Slideshow video song",
    "Sneha from Pune just ordered the Slideshow video song",
    "Rahul from Agra just ordered the Personalized Love Song Album",
    "Anjali from Bangalore just ordered the Personalized Love Song Album",
    "Priya from Patna just ordered the Slideshow video song",
    "Manoj from Kolkata just ordered the Slideshow video song",
    "Nisha from Surat just ordered the Slideshow video song",
    "Anjali from Lucknow just ordered the Slideshow video song",
    "Ankit from Ludhiana just ordered the Personalized Love Song Album",
    "Anjali from Ludhiana just ordered the Slideshow video song",
    "Priya from Vadodara just ordered the Personalized Love Song Album",
    "Pooja from Vadodara just ordered the Slideshow video song",
    "Rohan from Bangalore just ordered the Personalized Love Song Album",
    "Rohan from Ludhiana just ordered the Slideshow video song",
    "Manoj from Nagpur just ordered the Personalized Love Song Album",
    "Manoj from Chennai just ordered the Personalized Love Song Album",
    "Priya from Pune just ordered the Slideshow video song",
    "Priya from Agra just ordered the Slideshow video song",
    "Riya from Ludhiana just ordered the Slideshow video song",
    "Raj from Patna just ordered the Personalized Love Song Album",
    "Sneha from Nashik just ordered the Slideshow video song",
    "Kabir from Hyderabad just ordered the Personalized Love Song Album",
    "Karan from Lucknow just ordered the Personalized Love Song Album",
    "Pooja from Lucknow just ordered the Personalized Love Song Album",
    "Raj from Hyderabad just ordered the Slideshow video song",
    "Rohan from Vadodara just ordered the Slideshow video song",
    "Aditi from Kolkata just ordered the Personalized Love Song Album",
    "Neha from Kanpur just ordered the Personalized Love Song Album",
    "Raj from Bhopal just ordered the Personalized Love Song Album",
    "Abhishek from Ahmedabad just ordered the Personalized Love Song Album",
    "Arjun from Nashik just ordered the Personalized Love Song Album",
    "Rahul from Patna just ordered the Slideshow video song",
    "Kabir from Nashik just ordered the Personalized Love Song Album",
    "Pooja from Vadodara just ordered the Personalized Love Song Album",
    "Ankit from Patna just ordered the Personalized Love Song Album",
    "Nisha from Indore just ordered the Slideshow video song",
    "Nisha from Chennai just ordered the Slideshow video song",
    "Rohan from Delhi just ordered the Slideshow video song",
    "Priya from Pune just ordered the Personalized Love Song Album",
    "Vikram from Mumbai just ordered the Personalized Love Song Album",
    "Kabir from Kolkata just ordered the Slideshow video song",
    "Ankit from Surat just ordered the Personalized Love Song Album",
    "Anjali from Kanpur just ordered the Personalized Love Song Album",
    "Sneha from Ludhiana just ordered the Slideshow video song",
    "Rahul from Chennai just ordered the Slideshow video song",
    "Samantha from Kanpur just ordered the Slideshow video song",
    "Raj from Bangalore just ordered the Slideshow video song",
    "Rahul from Jaipur just ordered the Personalized Love Song Album",
    "Priya from Nashik just ordered the Personalized Love Song Album",
    "Nisha from Pune just ordered the Personalized Love Song Album",
    "Nisha from Patna just ordered the Slideshow video song",
    "Kabir from Chennai just ordered the Slideshow video song",
    "Aditi from Chennai just ordered the Slideshow video song",
    "Neha from Patna just ordered the Slideshow video song",
    "Anjali from Surat just ordered the Slideshow video song",
    "Karan from Patna just ordered the Personalized Love Song Album",
    "Simran from Bangalore just ordered the Personalized Love Song Album",
    "Kabir from Bangalore just ordered the Slideshow video song",
    "Arjun from Surat just ordered the Personalized Love Song Album",
    "Samantha from Kanpur just ordered the Slideshow video song",
    "Raj from Lucknow just ordered the Slideshow video song",
    "Nisha from Vadodara just ordered the Personalized Love Song Album",
    "Manoj from Vadodara just ordered the Personalized Love Song Album",
    "Samantha from Indore just ordered the Personalized Love Song Album",
    "Anjali from Kolkata just ordered the Personalized Love Song Album",
    "Rohan from Pune just ordered the Slideshow video song",
    "Nisha from Ahmedabad just ordered the Slideshow video song",
    "Riya from Vadodara just ordered the Slideshow video song",
    "Priya from Kanpur just ordered the Slideshow video song",
    "Rahul from Lucknow just ordered the Slideshow video song",
    "Abhishek from Bhopal just ordered the Personalized Love Song Album",
    "Ankit from Nagpur just ordered the Slideshow video song",
    "Samantha from Ludhiana just ordered the Personalized Love Song Album",
    "Aditi from Indore just ordered the Personalized Love Song Album",
    "Abhishek from Bangalore just ordered the Personalized Love Song Album",
    "Ankit from Jaipur just ordered the Personalized Love Song Album",
    "Riya from Nagpur just ordered the Personalized Love Song Album",
    "Abhishek from Indore just ordered the Personalized Love Song Album",
    "Karan from Jaipur just ordered the Slideshow video song",
    "Karan from Nagpur just ordered the Slideshow video song",
    "Aditi from Nashik just ordered the Personalized Love Song Album",
    "Priya from Chennai just ordered the Personalized Love Song Album",
    "Arjun from Jaipur just ordered the Personalized Love Song Album",
    "Riya from Agra just ordered the Personalized Love Song Album",
    "Nisha from Nashik just ordered the Personalized Love Song Album",
    "Vikram from Delhi just ordered the Slideshow video song",
    "Aditi from Ludhiana just ordered the Slideshow video song",
    "Anjali from Hyderabad just ordered the Personalized Love Song Album",
    "Anjali from Agra just ordered the Slideshow video song",
    "Manoj from Kanpur just ordered the Personalized Love Song Album",
    "Pooja from Surat just ordered the Personalized Love Song Album",
    "Arjun from Bhopal just ordered the Slideshow video song",
    "Rohan from Ludhiana just ordered the Personalized Love Song Album",
    "Manoj from Ludhiana just ordered the Personalized Love Song Album",
    "Vikram from Ludhiana just ordered the Slideshow video song",
    "Kabir from Pune just ordered the Slideshow video song",
    "Pooja from Ludhiana just ordered the Slideshow video song",
    "Karan from Nashik just ordered the Personalized Love Song Album",
    "Rohan from Chennai just ordered the Personalized Love Song Album",
    "Arjun from Kolkata just ordered the Personalized Love Song Album",
    "Rahul from Kolkata just ordered the Personalized Love Song Album",
    "Anjali from Indore just ordered the Personalized Love Song Album",
    "Raj from Bangalore just ordered the Personalized Love Song Album",
    "Manoj from Kanpur just ordered the Slideshow video song",
    "Karan from Kolkata just ordered the Slideshow video song",
    "Rohan from Chennai just ordered the Personalized Love Song Album",
    "Pooja from Surat just ordered the Slideshow video song",
    "Kabir from Jaipur just ordered the Personalized Love Song Album",
    "Nisha from Pune just ordered the Personalized Love Song Album",
    "Pooja from Patna just ordered the Slideshow video song",
    "Neha from Lucknow just ordered the Personalized Love Song Album",
    "Arjun from Indore just ordered the Personalized Love Song Album",
    "Ankit from Bangalore just ordered the Personalized Love Song Album",
    "Simran from Bhopal just ordered the Personalized Love Song Album",
    "Pooja from Lucknow just ordered the Personalized Love Song Album",
    "Pooja from Indore just ordered the Personalized Love Song Album",
    "Sneha from Nagpur just ordered the Personalized Love Song Album",
    "Sneha from Surat just ordered the Personalized Love Song Album",
    "Anjali from Ahmedabad just ordered the Slideshow video song",
    "Karan from Delhi just ordered the Personalized Love Song Album",
    "Sneha from Mumbai just ordered the Slideshow video song",
    "Rohan from Bangalore just ordered the Slideshow video song",
    "Nisha from Ludhiana just ordered the Personalized Love Song Album",
    "Pooja from Delhi just ordered the Personalized Love Song Album",
    "Aditi from Indore just ordered the Slideshow video song",
    "Rahul from Vadodara just ordered the Personalized Love Song Album",
    "Rahul from Ludhiana just ordered the Personalized Love Song Album",
    "Priya from Lucknow just ordered the Slideshow video song",
    "Vikram from Ahmedabad just ordered the Personalized Love Song Album",
    "Ankit from Jaipur just ordered the Slideshow video song",
    "Rohan from Lucknow just ordered the Slideshow video song",
    "Samantha from Pune just ordered the Personalized Love Song Album",
    "Arjun from Patna just ordered the Slideshow video song",
    "Simran from Nagpur just ordered the Personalized Love Song Album",
    "Arjun from Mumbai just ordered the Slideshow video song",
    "Rohan from Pune just ordered the Personalized Love Song Album",
    "Riya from Kolkata just ordered the Slideshow video song",
    "Karan from Mumbai just ordered the Personalized Love Song Album",
    "Pooja from Delhi just ordered the Slideshow video song",
    "Sneha from Agra just ordered the Personalized Love Song Album",
    "Aditi from Jaipur just ordered the Personalized Love Song Album",
    "Riya from Vadodara just ordered the Personalized Love Song Album",
    "Ankit from Mumbai just ordered the Personalized Love Song Album",
    "Ankit from Vadodara just ordered the Slideshow video song",
    "Raj from Indore just ordered the Slideshow video song",
    "Abhishek from Jaipur just ordered the Slideshow video song",
    "Priya from Delhi just ordered the Personalized Love Song Album",
    "Samantha from Patna just ordered the Personalized Love Song Album",
    "Vikram from Indore just ordered the Slideshow video song",
    "Kabir from Nashik just ordered the Slideshow video song",
    "Aditi from Patna just ordered the Slideshow video song",
    "Kabir from Bangalore just ordered the Slideshow video song",
    "Priya from Kolkata just ordered the Personalized Love Song Album",
    "Karan from Nagpur just ordered the Personalized Love Song Album",
    "Aditi from Surat just ordered the Personalized Love Song Album",
    "Vikram from Chennai just ordered the Slideshow video song",
    "Anjali from Bhopal just ordered the Personalized Love Song Album",
    "Nisha from Indore just ordered the Personalized Love Song Album",
    "Pooja from Kolkata just ordered the Slideshow video song",
    "Ankit from Nagpur just ordered the Slideshow video song",
    "Nisha from Nashik just ordered the Personalized Love Song Album",
    "Rohan from Vadodara just ordered the Personalized Love Song Album",
    "Samantha from Hyderabad just ordered the Personalized Love Song Album",
    "Anjali from Surat just ordered the Slideshow video song",
    "Neha from Pune just ordered the Slideshow video song",
    "Ankit from Patna just ordered the Personalized Love Song Album"
];

// export const getRandomNotification = () => {
//     const randomIndex = Math.floor(Math.random() * notifications.length);
//     return notifications[randomIndex];
// };


export const getRandomNotification = () => {
    const randomIndex = Math.floor(Math.random() * notifications.length);
    const notification = notifications[randomIndex];

    // Determine the icon based on the content
    const icon = notification.includes("Slideshow video song") ? "grid" : "heart";

    return { notification, icon };
};