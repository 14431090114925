import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import CookieBanner from "../src/components/CookieBanner.jsx";
import MenuPage from "../src/components/MenuPage.jsx";
import CookiePolicy from "../src/pages/TermAndCondition/CookiePolicy.jsx";
import India from "../src/pages/viralPages/IndiaPage.jsx";
import NotFoundPage from "../src/reusable/404_page.jsx";
import AlertFailure from "../src/reusable/AlertFail.jsx";
import AlertSuccess from "../src/reusable/AlertSuccess.jsx";
import loader from "./assets/dashboard/loader.gif";
import BottomNavbar from "./components/BottomNavbar";
import ContactPage from "./components/ContactPage";
import DefaultPageComponent from "./components/DefaultPageComponent";
import Footer from "./components/Footer";
import Navbar from "./components/Header";
import ProtectedRoute from "./layout/ProtectedRoute";

import valentineBanner from "../src/assets/dashboard/valentineBanner.jpg";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import FaqSection from "./pages/Faq/FaqSection";
import ForgotPassword from "./pages/forgetPassword/ForgotPassword";
import HomePage from "./pages/Home/HomePage";
import LoginPage from "./pages/Login/LoginPage";
import SignUpInsta from "./pages/Login/SignUpInsta.jsx";
import SignUpPage from "./pages/Login/SignUpPage";
import PartnersPage from "./pages/Partners/partners";
import PartnersThanksPage from "./pages/Partners/partnersThanks";
import PaymentPopup from "./pages/Payment/PaymentPopup";
import UserProfilePage from "./pages/Profile/UserProfilePage";
import AboutUs from "./pages/TermAndCondition/AboutUs";
import Cancellation from "./pages/TermAndCondition/Cancellation";
import PrivacyPolicy from "./pages/TermAndCondition/PrivacyPolicy";
import TermsOfService from "./pages/TermAndCondition/TermsOfService";
import UpgradePlan from "./pages/upgrade_Plans/UpgradePlan";
import ValentineCheckOut from "./pages/valentinesUi/ValentineCheckOut.jsx";
import ValentineForm from "./pages/valentinesUi/ValentineForm.jsx";
import ValentinePage from "./pages/valentinesUi/ValentinePage.jsx";
import ValentinePaymentFail from "./pages/valentinesUi/ValentinePaymentFail.jsx";
import ValentinePaymentSuccess from "./pages/valentinesUi/ValentinePaymentSuccess.jsx";
import ValentinesDelivery from "./pages/valentinesUi/ValentinesDelivery.jsx";
import SpotifyPlaylist from "./pages/valentinesUi/ValentinesUi.jsx";
import CustomSongPage from "./pages/viralPages/CustomSongPage.jsx";
import ModijiPage from "./pages/viralPages/modiPage.jsx";
import ParentsPage from "./pages/viralPages/parentsPage.jsx";
import GetAllVoice from "./pages/VoiceCreation/GetAllVoice";
import GetStartedPage from "./pages/VoiceCreation/GetStartedPage";
import SongList from "./pages/VoiceCreation/SongList";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const [isPartnersPage, setIsPartnersPage] = useState();
  const dispatch = useDispatch();
  const [aiVideoStatus, setAiVideoStatus] = useState("");
  const [aiVideoStatusModal, setAiVideoStatusModal] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const { job } = useSelector((state) => state.voices);
  const valentineId = location.pathname.startsWith("/valentine")
    ? location.pathname
    : "";

  const excludedRoutes = [
    "/india_song",
    "/modiji_song",
    "/custom_song_for_parents",
    "/partners",
    "/signupI",
    "/custom_song_page",
    "/valentine-form",
    "/valentine-product-checkout",
    "/valentine-payment-success",
    "/valentine-payment-fail",
    "/terms-of-service",
    valentineId,
  ];

  const valentineRoutes = [
    "/valentine-form",
    "/valentine-product-checkout",
    "/valentine-payment-success",
    "/valentine-payment-fail",
    "/terms-of-service",
    "/valentines",
    "/valentines/202401",
    "/valentines/202402",
    "/valentines/202403",
    "/valentines/202404",
    valentineId
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // }, []);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    setIsPartnersPage(
      location.pathname === "/partners" ||
        location.pathname === "/partners-thanks" ||
        location.pathname === "/india_song" ||
        location.pathname === "/modiji_song" ||
        location.pathname === "/custom_song_for_parents" ||
        location.pathname === "/partners" ||
        location.pathname === "/custom_song_page" ||
        location.pathname === "/valentine-form" ||
        location.pathname === "/valentine-product-checkout" ||
        location.pathname === "/valentine-payment-success" ||
        location.pathname === "/valentine-payment-fail" ||
        location.pathname.startsWith("/valentines") ||
        location.pathname.startsWith("/valentine-melodies")
    );
  }, [location.pathname]);
  useEffect(() => {
    const lastShownTime = localStorage.getItem("bannerLastShown");
    const oneHour = 60 * 60 * 1000;

    if (!lastShownTime || Date.now() - lastShownTime > oneHour) {
      setShowBanner(true);
      localStorage.setItem("bannerLastShown", Date.now().toString());
    }
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen text-white">
        <img src={loader} alt="loader" />
      </div>
    );
  }

  return (
    <>
      {showBanner && !valentineRoutes.includes(location.pathname) && (
        <div className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-[9999] px-6">
          <div
            className=" bg-pink-100 text-black p-4 max-w-3xl w-full relative z-[10000] rounded-lg md:bg-cover"
            style={{
              backgroundImage: `url(${valentineBanner})`,
              backgroundPosition: "center",
            }}
          >
            <button
              onClick={() => setShowBanner(false)}
              className="absolute top-0 right-2 text-black font-bold  text-2xl hover:text-black"
            >
              X
            </button>
            <div className="text-center md:mt-8 mb-2">
              <h2 className="text-2xl md:text-3xl font-bold mb-4 ">
                <span className="">I Made a Song</span> <br />
                Valentine’s Special!{" "}
              </h2>
              <p className="mb-4 text-md text-center font-semibold  ">
                ORDER YOUR PERSONALIZED LOVE SONG <br />
                <span className="mt-1">
                  ALBUM OR SLIDESHOW VIDEO SONG NOW! 💖
                </span>
              </p>
              <Link
                to="/valentines"
                className="bg-[#f44336] text-white cursor-pointer py-1 px-4  rounded-lg text-xl font-semibold shadow-md hover:bg-pink-700 transition duration-300"
              >
                Click Here
              </Link>
            </div>
          </div>
        </div>
      )}

      {/* <Router> */}
      <CookieBanner />
      {!isPartnersPage && <Navbar />}
      <Routes>
        <Route
          path="/partners"
          element={
            <>
              {" "}
              <PartnersPage />{" "}
            </>
          }
        />
        <Route
          path="/partners-thanks"
          element={
            <>
              {" "}
              <PartnersThanksPage />{" "}
            </>
          }
        />

        {/* Index page  */}
        <Route
          path="/"
          element={
            <>
              {" "}
              <HomePage /> <Footer />{" "}
            </>
          }
        />

        {/* Menu page  */}
        <Route path="/menu" element={<MenuPage />} />

        {/* Authentication  */}
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/signin" element={<LoginPage />} />
        <Route path="/signupI" element={<SignUpInsta />} />

        <Route
          path="/get-started"
          element={
            <ProtectedRoute>
              {" "}
              <GetStartedPage />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/all-voices"
          element={
            <ProtectedRoute>
              {" "}
              <GetAllVoice />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/song-list"
          element={
            <ProtectedRoute>
              {" "}
              <SongList />{" "}
            </ProtectedRoute>
          }
        />

        {/* User Profile  */}
        <Route path="/user-profile" element={<UserProfilePage />} />

        {/* Default page  */}
        <Route
          path="/default-page"
          element={
            <>
              {" "}
              <DefaultPageComponent /> <Footer />{" "}
            </>
          }
        />

        {/* Main Dashboard */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              {" "}
              <DashboardPage />{" "}
            </ProtectedRoute>
          }
        />

        {/* Subscription */}
        <Route
          path="/pricing"
          element={
            <ProtectedRoute>
              {" "}
              <>
                {" "}
                <UpgradePlan /> <Footer />{" "}
              </>{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/alert-success"
          element={
            <ProtectedRoute>
              {" "}
              <>
                {" "}
                <AlertSuccess />{" "}
              </>{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/alert-failure"
          element={
            <ProtectedRoute>
              {" "}
              <>
                {" "}
                <AlertFailure />{" "}
              </>{" "}
            </ProtectedRoute>
          }
        />
        {/* Subscription */}
        <Route
          path="/payment-checkout"
          element={
            <ProtectedRoute>
              {" "}
              <>
                {" "}
                <PaymentPopup /> <Footer />{" "}
              </>{" "}
            </ProtectedRoute>
          }
        />

        {/* Privacy policy */}
        <Route
          path="/cancellation-policy"
          element={
            <>
              {" "}
              <Cancellation /> <Footer />{" "}
            </>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <>
              {" "}
              <PrivacyPolicy /> <Footer />{" "}
            </>
          }
        />
        <Route
          path="/terms-of-service"
          element={
            <>
              <TermsOfService /> <Footer />
            </>
          }
        />
        <Route
          path="/about-us"
          element={
            <>
              {" "}
              <AboutUs /> <Footer />{" "}
            </>
          }
        />
        <Route
          path="/cookie-policy"
          element={
            <>
              {" "}
              <CookiePolicy /> <Footer />
            </>
          }
        />

        <Route
          path="/faq"
          element={
            <>
              <FaqSection /> <Footer />
            </>
          }
        />

        <Route
          path="/contact"
          element={
            <>
              {" "}
              <ContactPage /> <Footer />{" "}
            </>
          }
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        {/* 404 page  */}
        <Route path="/404" element={<NotFoundPage />} />

        {/* Catch-all route for undefined URLs */}
        <Route path="*" element={<NotFoundPage />} />

        {/* Viral Pages */}
        <Route
          path="/india_song"
          element={
            <>
              <India />
            </>
          }
        />
        <Route
          path="/modiji_song"
          element={
            <>
              <ModijiPage />
            </>
          }
        />
        <Route
          path="/custom_song_for_parents"
          element={
            <>
              <ParentsPage />
            </>
          }
        />
        <Route
          path="/custom_song_page"
          element={
            <>
              <CustomSongPage />
            </>
          }
        />
        {/* Viral Pages */}
        <Route
          path="/valentines/:id"
          element={
            <>
              <SpotifyPlaylist />
            </>
          }
        />
        <Route
          path="/valentine-melodies/:id"
          element={
            <>
              <ValentinesDelivery />
            </>
          }
        />
        <Route
          path="/valentines"
          element={
            <>
              <ValentinePage />
            </>
          }
        />
        <Route
          path="/valentine-form"
          element={
            <>
              <ValentineForm />
            </>
          }
        />
        <Route
          path="/valentine-product-checkout"
          element={
            <>
              <ValentineCheckOut />
            </>
          }
        />
        <Route
          path="/valentine-payment-success"
          element={
            <>
              <ValentinePaymentSuccess />
            </>
          }
        />
        <Route
          path="/valentine-payment-fail"
          element={
            <>
              <ValentinePaymentFail />
            </>
          }
        />
      </Routes>

      {/* {isMobile && <BottomNavbar />} Show BottomNavbar only on mobile */}

      {isMobile && !excludedRoutes.includes(location.pathname) && (
        <BottomNavbar />
      )}
    </>
  );
};

export default App;

// useEffect(() => {
//   let intervalId;

//   let jid = localStorage.getItem("jobIdLocal");

//   const checkJobStatus = async () => {
//     try {
//       const response = await dispatch(generateAiClone(jid)).unwrap();

//       if (response.status === "Completed") {
//         localStorage.removeItem("jobIdLocal");
//         clearInterval(intervalId);
//         setAiVideoStatus(
//           "Your singing selfie video is ready! Check it out in your video library."
//         );
//         setAiVideoStatusModal(true);
//       }
//       if (response.status === "Failed") {
//         localStorage.removeItem("jobIdLocal");
//         clearInterval(intervalId);
//         setAiVideoStatus(
//           "Unable to create your singing selfie video. Please try again."
//         );
//         setAiVideoStatusModal(true);
//       }
//     } catch (error) {

//     }
//   };
//   if (jid) {

//     intervalId = setInterval(checkJobStatus, 180000);
//   } else {

//   }

//   return () => clearInterval(intervalId);
// }, [localStorage.getItem("jobIdLocal"), dispatch, job]);
