import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FaBars } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import Home from "../mainHome/Home.jsx";
import Settings from "../settings/settings.jsx";
import VideoCollage from "../VideoCollage/VideoCollagePage.jsx";
import GetAllVoice from "../VoiceCreation/GetAllVoice";
import SongGeneratedPage from "../VoiceCreation/SongGeneratedPage";
import Sidebar from "./Sidebar";
import AudioCloning from "../audioCloning/AudioCloning.jsx";
import SongLibraryTabs from "../Dashboard/SongLibraryTabs.jsx";
import { useSelector } from "react-redux";
import { IoIosCloseCircle } from "react-icons/io";
// import ImageVideo from "../imageVideo/ImageVideo.jsx";

function CreateMelody() {
  const [activeContent, setActiveContent] = useState("Create Song");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { userDetails, loading, error } = useSelector((state) => state.user);
  const [showVideoPreview, setShowVideoPreview] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/signin");
    }
  }, [token, navigate]);

  useEffect(() => {
    if (location.state?.tab) {
      setActiveContent(location.state.tab);
    }
  }, [location.state]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      try {
        if (
          userDetails &&
          !userDetails.is_video_generated &&
          !localStorage.getItem("modalShown")
        ) {
          setShowModal(true);
          localStorage.setItem("modalShown", "true");
        }
      } catch (error) {
        console.error("Error in timeout:", error);
      }
    }, 2000);

    const interval = setInterval(() => {
      try {
        if (userDetails && !userDetails.is_video_generated) {
          setShowModal(true);
          localStorage.setItem("modalShown", "true");
          setTimeout(() => {
            localStorage.removeItem("modalShown");
          }, 5000); // Clear the flag after 5 seconds to allow re-display
        }
      } catch (error) {
        console.error("Error in interval:", error);
      }
    }, 1000 * 60 * 60); // Repeat every hour

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [userDetails]);

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSingingClick = () => {
    setShowModal(false);
    navigate("/dashboard", { state: { tab: "Singing Selfie" } });
  };

  const handleSlideShowClick = () => {
    setShowModal(false);
    navigate("/dashboard", { state: { tab: "Slideshow Video" } });
  };
  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
  };

  return (
    <main
      className="overflow-hidden bg-neutral-900 relative"
      style={{ height: "100vh" }}
    >
      {/* Add Meta Pixel Script using Helmet */}
      <Helmet>
        <script type="text/javascript">
          {`
            (function(e, t, n) {
              if (e.snaptr) return;
              var a = e.snaptr = function() {
                a.handleRequest ? a.handleRequest.apply(a, arguments) : a.queue.push(arguments);
              };
              a.queue = [];
              var s = 'script', r = t.createElement(s);
              r.async = true;
              r.src = 'https://sc-static.net/scevent.min.js';
              var u = t.getElementsByTagName(s)[0];
              u.parentNode.insertBefore(r, u);
            })(window, document, 'https://sc-static.net/scevent.min.js');
            snaptr('init', 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', {
              'user_email': '__INSERT_USER_EMAIL__'
            });
            snaptr('track', 'SIGN_UP');
          `}
        </script>

        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '925192532364818');
            fbq('track', 'PageView');
            fbq('track', 'Lead');
          `}
        </script>
        <noscript>
          {
            "<img height='1' width='1' style='display:none' src='https://www.facebook.com/tr?id=925192532364818&ev=PageView&noscript=1' />"
          }
        </noscript>

        {/* Google Ads Conversion Script */}
        <script type="text/javascript">
          {`
            gtag('event', 'conversion', {
              'send_to': 'AW-16753165277/Dj5BCJTJy_gZEN2HxLQ-',
              'value': 1.0,
              'currency': 'INR'
            });
          `}
        </script>
      </Helmet>

      {/* Toggle Button for Small Screens */}
      <motion.button
        initial={{ y: "-100%" }}
        animate={{ y: 0 }}
        transition={{ duration: 0.3 }}
        className="md:hidden fixed top-4 left-4 z-50 text-white navbar-menu-logo"
        onClick={handleSidebarToggle}
      >
        <FaBars size={20} />
      </motion.button>
      <div className="flex max-md:flex-col header-drawer-bg ">
        <motion.div
          initial={{ x: "-100%" }}
          animate={{ x: 0 }}
          transition={{ duration: 0.2 }}
          className="w-[17%] max-md:ml-0 max-md:w-full bg-black position-sticky"
        >
          <div
            className={`fixed inset-0 z-40 transition-transform md:static md:translate-x-0 ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
              }`}
            style={{ height: "100vh" }}
          >
            <Sidebar
              onItemClick={setActiveContent}
              onClose={handleSidebarClose}
              setIsSidebarOpen={setIsSidebarOpen}
              isSidebarOpen={isSidebarOpen}
              activeContent={activeContent}
            />
          </div>
        </motion.div>

        <div className="ml-5 w-[83%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col mt-8  w-full max-md:mt-2 max-md:max-w-full">
            <div className="mt-10 all-list-height">
              {/* Conditionally render components based on activeContent */}
              {activeContent === "Create Song" && <SongGeneratedPage />}
              {activeContent === "Library" && <SongLibraryTabs />}
              {/* {activeContent === "Library" && <GetAllVoice />} */}
              {activeContent === "Profile" && <Settings />}
              {activeContent === "Slideshow Video" && <VideoCollage />}
              {activeContent === "Sample Songs" && <Home />}
              {activeContent === "Singing Selfie" && <AudioCloning />}
              {/* {activeContent === "Image Video" && <ImageVideo />} */}
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
          <motion.div
            className="bg-gradient-to-r from-black via-gray-800 to-gray-900 rounded-lg max-w-lg w-full p-6 transform  relative hover:scale-105 border-1 border-transparent"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
          >
            {/* Outer Shadow for Depth */}
            <div className="absolute inset-0 rounded-lg shadow-2xl blur-md opacity-30 bg-gradient-to-r from-gray-700 via-gray-800 to-black" />

            {/* Close Icon */}
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-white hover:text-gray-200 transition duration-200 transform hover:scale-110"
            >
              <IoIosCloseCircle className="text-3xl" />
            </button>

            <h2 className="text-3xl font-semibold text-center text-white mb-6">
              Try Something Fun!
            </h2>
            <p className="text-white text-lg text-center mb-4">
              We've got exciting new features for you to explore!
              <br />
              Want to create a{" "}
              <strong className="text-white">Singing Selfie Video</strong> or a{" "}
              <strong className="text-white">Slideshow Video</strong>? 🎥
              <br />
              We bet you'll love it!
            </p>

            <div className="text-center mb-4">
              <button
                onClick={() => setShowVideoPreview(!showVideoPreview)}
                className="custom-button text-white px-6 py-2 rounded-lg hover:bg-gray-600 transform transition duration-200 hover:scale-110"
              >
                Watch AI Sample
              </button>
            </div>

            {showVideoPreview && (
              <div className="mb-4">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  className="flex justify-center gap-4"
                >
                  <div className="w-32 h-32 bg-gray-800 rounded-lg overflow-hidden transform hover:scale-110 transition duration-300">
                    <video width="100%" height="100%" loop autoPlay>
                      <source
                        src="https://imas-general.s3.eu-central-003.backblazeb2.com/Friendship_song_am+1.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </motion.div>
              </div>
            )}

            <div className="flex justify-center gap-4">
              <button
                className="bg-gray-700 text-white px-6 py-2 rounded-lg hover:bg-gray-600 transform transition duration-200 hover:scale-110"
                onClick={handleSingingClick}
              >
                Singing Selfie
              </button>
              <button
                className="bg-gray-700 text-white px-6 py-2 rounded-lg hover:bg-gray-600 transform transition duration-200 hover:scale-110"
                onClick={handleSlideShowClick}
              >
                Slideshow Video
              </button>
            </div>
          </motion.div>
        </div>
      )}
    </main>
  );
}

export default CreateMelody;
