import { notification } from "antd";

const valentineNotification = (type, description, durationTime = 3) => {
  const isMobile = window.innerWidth <= 768;
  notification.config({
    top: isMobile ? 20 : 79,
  });
  setTimeout(() => {
    notification[type]({
      description: description,
      placement: "top",
      duration: durationTime,
      style: {
        transition: "top 0.5s ease-in-out",
        opacity: 0.9,
        padding: isMobile ? "11px" : "11px",
        zIndex: 9999,
        background: "linear-gradient(45deg, rgb(255, 152, 187), rgb(255, 211, 225), rgb(255, 123, 168))",
                border: "none",
        borderRadius: "8px",
        fontWeight: 500,
        fontSize: isMobile ? "14px" : "16px",
        color: "rgb(255, 255, 255)" // Ensures the text color remains readable on reddish background
      },
      icon: null, // Hides the default icon
      closeIcon: null, // Hides the close button
    });
  }, 0);
};

export default valentineNotification;
