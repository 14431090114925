import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import apiUrl from "../../services/api";
import axios from "axios";
import valentineNotification from "../../hooks/valentineNotification";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import PageLoader from "../../components/PageLoader";
import ValantineGuideForm from "./ValatineGuideForm";
import showNotification from "../../hooks/notificationHook";
import ValentineTermAndCondition from "./valentineTermAndConditions/ValentineTermAndCondition";
import { RiCoupon3Fill } from "react-icons/ri";
import { motion } from "framer-motion";

const ValentineForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedOption = location.state?.selectedOption;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showHowToUse, setShowHowToUse] = useState(false);
  const [showFormDetails, setShowFormDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const dropdownRef = useRef(null);
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [currentComponent, setCurrentComponent] = useState(null);
  const [checkTermsAndConditions, setCheckTermsAndConditions] = useState(false);
  const [checkHandleHold, setCheckHandleHold] = useState(false);

  const modalRef = useRef(null); // Reference for the modal

  useEffect(() => {
    if (showHowToUse) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showHowToUse]);
  // Close modal when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowHowToUse(false);
      }
    };

    if (showHowToUse) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showHowToUse]);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    city: "",
    country: "",
    state: "",
    email: "",
    phone: "",
    dedicationName: "",
    gender: "",
    language: "",
    characteristics: [],
    image4video: [],
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const characteristics = [
    "Loving",
    "Caring",
    "Understanding",
    "Respectful",
    "Loyal",
    "Affectionate",
    "Honest",
    "Adventurous",
    "Humorous",
    "Ambitious",
    "Kind-hearted",
    "Creative",
    "Intelligent",
    "Trustworthy",
    "Thoughtful",
    "Confident",
    "Generous",
    "Forgiving",
    "Charming",
    "Gentle",
    "Hardworking",
    "Selfless",
    "Warm-hearted",
    "Determined",
    "Cheerful",
  ];

  const [previewImage, setPreviewImage] = useState(null);

  //   const handleImageChange = (e) => {
  //     const file = e.target.files[0];
  //     if (file) {
  //       setFormData((prev) => ({
  //         ...prev,
  //         image4video: file,
  //       }));
  //       const imageUrl = URL.createObjectURL(file);
  //       setPreviewImage(imageUrl);
  //     }
  //   };

  const validExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".webp"];
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    // Validate file extensions
    const invalidFiles = files.filter((file) => {
      const extension = file.name
        .slice(file.name.lastIndexOf("."))
        .toLowerCase();
      return !validExtensions.includes(extension);
    });

    if (invalidFiles.length > 0) {
      valentineNotification(
        "error",
        "Invalid file format! Please upload images in JPG, JPEG, PNG, GIF, BMP, or WEBP format."
      );
      e.target.value = "";
      return;
    }

    if (selectedOption === 2) {
      // Combine existing and new files
      const combinedFiles = [...formData.image4video, ...files];

      if (combinedFiles.length < 2) {
        valentineNotification(
          "error",
          "Please upload at least 2 images for Video Song."
        );
        return;
      }
      if (combinedFiles.length > 10) {
        valentineNotification("error", "You can only upload up to 10 images.");
        return;
      }

      setFormData((prev) => ({
        ...prev,
        image4video: combinedFiles,
      }));

      const combinedImageUrls = combinedFiles.map((file) =>
        URL.createObjectURL(file)
      );
      setPreviewImage(combinedImageUrls);
      valentineNotification("info", "Image uploaded successfully");
    } else if (selectedOption === 1) {
      if (files.length > 1) {
        valentineNotification(
          "error",
          "You can only upload one image for Love Album."
        );
        return;
      }

      setFormData((prev) => ({
        ...prev,
        image4video: files,
      }));

      const imageUrls = files.map((file) => URL.createObjectURL(file));
      setPreviewImage(imageUrls);
      valentineNotification("info", "Image uploaded successfully");
    }
  };

  const handleRemoveImage = (index) => {
    if (selectedOption === 1) {
      document.getElementById("fileInput").value = "";
    }
    if (selectedOption === 2) {
      document.getElementById("fileInput").value = "";
    }
    const updatedImages = [...formData.image4video];
    updatedImages.splice(index, 1);

    setFormData((prev) => ({
      ...prev,
      image4video: updatedImages,
    }));

    const updatedPreviewImages = [...previewImage];
    updatedPreviewImages.splice(index, 1);

    setPreviewImage(updatedPreviewImages);
    valentineNotification("info", "Image removed successfully");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const newCharacteristics = checked
      ? [...formData.characteristics, value]
      : formData.characteristics.filter((char) => char !== value);
    setFormData({ ...formData, characteristics: newCharacteristics });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNavigation = (responseData) => {
    const dataToPass = {
      userName: formData.firstName,
      email: formData.email,
      contact: formData.phone,
      responseData: responseData,
    };
    // setCheckHandleHold(true);
    navigate("/valentine-product-checkout", { state: dataToPass });
  };

  const fetchOccasions = async () => {
    const payload = { occasion_name: "valentine" };

    try {
      const response = await axios.post(apiUrl.fetchOccassion, payload);
      const data = response.data;
      const occassionId = data[0].id;
      localStorage.setItem("occassionId", data[0].id);
      return data;
    } catch (error) {
      console.error("Error fetching occasions:", error);
    }
  };

  useEffect(() => {
    fetchOccasions();
  }, []);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   // Validation logic
  //   const nameRegex = /^[a-zA-Z ]+$/;
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   const phoneRegex = /^[0-9]{10}$/;

  //   // Validate each form field
  //   if (!nameRegex.test(formData.firstName)) {
  //     valentineNotification("error", "First name must contain only letters.");
  //     return;
  //   }
  //   if (!nameRegex.test(formData.lastName)) {
  //     valentineNotification("error", "Last name must contain only letters.");
  //     return;
  //   }
  //   if (!emailRegex.test(formData.email)) {
  //     valentineNotification("error", "Enter a valid email address.");
  //     return;
  //   }
  //   if (!phoneRegex.test(formData.phone)) {
  //     valentineNotification("error", "Phone number must be 10 digits.");
  //     return;
  //   }
  //   if (!nameRegex.test(formData.dedicationName)) {
  //     valentineNotification(
  //       "error",
  //       "Dedication name must contain only letters."
  //     );
  //     return;
  //   }

  //   if (formData.gender === "") {
  //     valentineNotification("error", "Please select singer gender.");
  //     return;
  //   }

  //   if (formData.language === "") {
  //     valentineNotification("error", "Please select language.");
  //     return;
  //   }
  //   if (!checkTermsAndConditions) {
  //     valentineNotification("error", "Please accept terms and conditions.");
  //     return;
  //   }

  //   if (
  //     formData.characteristics.length < 3 ||
  //     formData.characteristics.length > 10
  //   ) {
  //     valentineNotification(
  //       "error",
  //       `Please select between 3 and 10 characteristics. You have selected ${formData.characteristics.length}.`
  //     );
  //     return;
  //   }

  //   // if (formData.image4video === null) {
  //   //   valentineNotification("error", "Please select the image.");
  //   //   return;
  //   // }
  //   if (selectedOption === 2 && formData.image4video.length < 2) {
  //     valentineNotification(
  //       "error",
  //       "Please upload at least 2 images for Video Song."
  //     );
  //     return;
  //   }

  //   if (selectedOption === 1 && formData.image4video.length > 1) {
  //     valentineNotification(
  //       "error",
  //       "You can only upload one image for Love Album."
  //     );
  //     return;
  //   }

  //   const payload = new FormData();
  //   payload.append("occasion_id", localStorage.getItem("occassionId"));
  //   payload.append("first_name", formData.firstName);
  //   payload.append("last_name", formData.lastName);
  //   payload.append("mobile", formData.phone);
  //   payload.append("email", formData.email);
  //   payload.append("street_address", null);
  //   payload.append("town", formData.city);
  //   payload.append("state", null);
  //   payload.append("region", null);
  //   payload.append("postcode", null);
  //   payload.append("dedicated_name", formData.dedicationName);
  //   payload.append("package_type", selectedOption);
  //   payload.append("singer_gender_preferance", formData.gender);
  //   payload.append(
  //     "person_characteristics",
  //     JSON.stringify(formData.characteristics)
  //   );
  //   payload.append("language", formData.language);
  //   // if (formData.image4video) {
  //   //   payload.append("image4video",formData.file || null);
  //   // }
  //   // formData.image4video.forEach((file) => {
  //   //   payload.append("image4video", file);
  //   // });
  //   if (formData.image4video && formData.image4video.length > 0) {
  //     formData.image4video.forEach((file) => {
  //       payload.append(`image4video`, file);
  //     });
  //   } else {
  //     // Send an empty file if required by the backend
  //     const emptyFile = new Blob([], { type: "application/octet-stream" });
  //     payload.append("image4video", emptyFile);
  //   }

  //   try {
  //     // Show loader
  //     setLoading(true);

  //     const response = await axios.post(apiUrl.saveOccassionForm, payload, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });

  //     // Hide loader after success
  //     setLoading(false);

  //     localStorage.setItem("formNumber", response.data.form_no);
  //     handleNavigation(response.data);
  //     valentineNotification(
  //       "success",
  //       "Your song dedication form has been submitted successfully!"
  //     );
  //   } catch (error) {
  //     // Hide loader after error
  //     setLoading(false);

  //     console.error("Error submitting the form:", error);
  //     valentineNotification(
  //       "error",
  //       "Failed to submit the form. Please try again."
  //     );
  //   }
  // };

  const handlePreview = () => {
    // const trimmedFormData = {
    //   ...formData,
    //   firstName: formData.firstName.trim(),
    //   lastName: formData.lastName.trim(),
    //   dedicationName: formData.dedicationName.trim(),
    //   email: formData.email.trim(),
    // };

    // // Regex allowing only letters and single spaces between words (no consecutive spaces)
    // const nameRegex = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;
    // const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // const phoneRegex = /^[0-9]{10}$/;

    // // Validate each form field
    // if (!nameRegex.test(trimmedFormData.firstName)) {
    //   valentineNotification("error", "First name must contain only letters.");
    //   return;
    // }
    // if (!nameRegex.test(trimmedFormData.lastName)) {
    //   valentineNotification("error", "Last name must contain only letters.");
    //   return;
    // }
    // if (!nameRegex.test(trimmedFormData.city.trim())) {
    //   valentineNotification("error", "City name must contain only letters.");
    //   return;
    // }
    // if (!emailRegex.test(trimmedFormData.email)) {
    //   valentineNotification("error", "Enter a valid email address.");
    //   return;
    // }
    // if (!phoneRegex.test(trimmedFormData.phone)) {
    //   valentineNotification("error", "Phone number must be 10 digits.");
    //   return;
    // }
    // if (!nameRegex.test(trimmedFormData.dedicationName)) {
    //   valentineNotification(
    //     "error",
    //     "Dedication name must contain only letters and single spaces."
    //   );
    //   return;
    // }

    // if (trimmedFormData.gender === "") {
    //   valentineNotification("error", "Please select singer gender.");
    //   return;
    // }

    // if (trimmedFormData.language === "") {
    //   valentineNotification("error", "Please select language.");
    //   return;
    // }
    // if (!checkTermsAndConditions) {
    //   valentineNotification("error", "Please accept terms and conditions.");
    //   return;
    // }

    // if (
    //   trimmedFormData.characteristics.length < 3 ||
    //   trimmedFormData.characteristics.length > 10
    // ) {
    //   valentineNotification(
    //     "error",
    //     `Please select between 3 and 10 characteristics. You have selected ${trimmedFormData.characteristics.length}.`
    //   );
    //   return;
    // }

    // if (selectedOption === 2 && trimmedFormData.image4video.length < 2) {
    //   valentineNotification(
    //     "error",
    //     "Please upload at least 2 images for Video Song."
    //   );
    //   return;
    // }

    // if (selectedOption === 1 && trimmedFormData.image4video.length > 1) {
    //   valentineNotification(
    //     "error",
    //     "You can only upload one image for Love Album."
    //   );
    //   return;
    // }

    // setShowFormDetails(true);

    valentineNotification("error", "No order has been taken at this time.");
  };

  const handlehold = (e) => {
    e.preventDefault();
    setCheckHandleHold(true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Trim form fields to prevent leading/trailing spaces
    const trimmedFormData = {
      ...formData,
      firstName: formData.firstName.trim(),
      lastName: formData.lastName.trim(),
      dedicationName: formData.dedicationName.trim(),
      email: formData.email.trim(),
    };

    // Regex allowing only letters and single spaces between words (no consecutive spaces)
    const nameRegex = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^[0-9]{10}$/;

    // Validate each form field
    if (!nameRegex.test(trimmedFormData.firstName)) {
      valentineNotification("error", "First name must contain only letters.");
      return;
    }
    if (!nameRegex.test(trimmedFormData.lastName)) {
      valentineNotification("error", "Last name must contain only letters.");
      return;
    }
    if (!nameRegex.test(trimmedFormData.city.trim())) {
      valentineNotification("error", "City name must contain only letters.");
      return;
    }
    if (!emailRegex.test(trimmedFormData.email)) {
      valentineNotification("error", "Enter a valid email address.");
      return;
    }
    if (!phoneRegex.test(trimmedFormData.phone)) {
      valentineNotification("error", "Phone number must be 10 digits.");
      return;
    }
    if (!nameRegex.test(trimmedFormData.dedicationName)) {
      valentineNotification(
        "error",
        "Dedication name must contain only letters and single spaces."
      );
      return;
    }

    if (trimmedFormData.gender === "") {
      valentineNotification("error", "Please select singer gender.");
      return;
    }

    if (trimmedFormData.language === "") {
      valentineNotification("error", "Please select language.");
      return;
    }
    if (!checkTermsAndConditions) {
      valentineNotification("error", "Please accept terms and conditions.");
      return;
    }

    if (
      trimmedFormData.characteristics.length < 3 ||
      trimmedFormData.characteristics.length > 10
    ) {
      valentineNotification(
        "error",
        `Please select between 3 and 10 characteristics. You have selected ${trimmedFormData.characteristics.length}.`
      );
      return;
    }

    if (selectedOption === 2 && trimmedFormData.image4video.length < 2) {
      valentineNotification(
        "error",
        "Please upload at least 2 images for Video Song."
      );
      return;
    }

    if (selectedOption === 1 && trimmedFormData.image4video.length > 1) {
      valentineNotification(
        "error",
        "You can only upload one image for Love Album."
      );
      return;
    }

    const payload = new FormData();
    payload.append("occasion_id", localStorage.getItem("occassionId"));
    payload.append("first_name", trimmedFormData.firstName);
    payload.append("last_name", trimmedFormData.lastName);
    payload.append("mobile", trimmedFormData.phone.trim());
    payload.append("email", trimmedFormData.email);
    payload.append("street_address", null);
    payload.append("town", trimmedFormData.city.trim());
    payload.append("state", null);
    payload.append("region", null);
    payload.append("postcode", null);
    payload.append("dedicated_name", trimmedFormData.dedicationName);
    payload.append("package_type", selectedOption);
    payload.append("singer_gender_preferance", trimmedFormData.gender);
    payload.append(
      "person_characteristics",
      JSON.stringify(trimmedFormData.characteristics)
    );
    payload.append("language", trimmedFormData.language);

    if (trimmedFormData.image4video && trimmedFormData.image4video.length > 0) {
      trimmedFormData.image4video.forEach((file) => {
        payload.append(`image4video`, file);
      });
    } else {
      const emptyFile = new Blob([], { type: "application/octet-stream" });
      payload.append("image4video", emptyFile);
    }

    try {
      setLoading(true);

      const response = await axios.post(apiUrl.saveOccassionForm, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setLoading(false);

      localStorage.setItem("formNumber", response.data.form_no);
      handleNavigation(response.data);
      valentineNotification(
        "success",
        "Your song dedication form has been submitted successfully!"
      );
    } catch (error) {
      setLoading(false);
      console.error("Error submitting the form:", error);
      valentineNotification(
        "error",
        "Failed to submit the form. Please try again."
      );
    }
  };
  const handleCancelClick = () => {
    navigate("/valentines");
  };

  const handleHowToUse = () => {
    setShowHowToUse(true);
  };

  const openModalWithComponent = (component) => {
    setIsPdfModalOpen(true);
    setCurrentComponent(component);
  };

  const closepdfModal = () => {
    setIsPdfModalOpen(false);
    setCurrentComponent(null);
  };

  return (
    <>
      <div className="bg-pink-100 min-h-screen flex items-center justify-center p-4">
        <div className="absolute top-1 left-1 md:left-10 animate-ping sm:hidden ">
          ❤️
        </div>
        <div className="absolute top-20 right-6 md:right-16 animate-ping">
          💕
        </div>

        <div className="bg-white rounded-lg shadow-lg py-4 px-2 w-full max-w-3xl border-2">
          <div className="flex items-center space-x-10 mb-2 justify-between">
            <button
              onClick={handleCancelClick}
              className="text-left text-2xl mr-1 font-bold"
              aria-label="Go back"
            >
              <IoArrowBackCircleOutline size={32} />
            </button>
            <h1 className="text-lg md:text-3xl font-bold text-pink-600  md:text-center">
              Order Form
            </h1>

            <button
              className="flex justify-end text-sm md:text-lg items-center p-1 px-2 bg-pink-600 text-white rounded-lg shadow-lg hover:bg-pink-700 transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none"
              onClick={handleHowToUse}
            >
              How to use
            </button>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-base font-medium text-black mb-1">
                  Your First Name
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="w-full border-gray-200 px-4 border rounded-md  shadow-sm focus:ring-red-500 focus:border-red-500 focus:outline-none"
                  placeholder="Arjun"
                  maxLength={30}
                />
              </div>

              <div>
                <label className="block text-base font-medium text-black mb-1">
                  Your Last Name
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="w-full border-gray-200 px-4 border rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 focus:outline-none"
                  placeholder="Mishra"
                  maxLength={30}
                />
              </div>

              <div>
                <label className="block text-base font-medium text-black mb-1">
                  Your City
                </label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  className="w-full border-gray-200 px-4 border rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 focus:outline-none"
                  placeholder="Mumbai"
                />
              </div>

              <div>
                <label className="block text-base font-medium text-black mb-1">
                  Your Email
                  <p className="text-sm  text-gray-500">
                    (Please ensure you type the correct email address since the
                    order will be delivered to your email address)
                  </p>
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full border-gray-200 px-4 border rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 focus:outline-none"
                  placeholder="XYZ@gmail.com"
                />
              </div>

              <div>
                <label className="block text-base font-medium text-black mb-1">
                  Your Mobile
                  <p className="text-sm  text-gray-500">
                    (Please ensure the number entered is correct. Please DO NOT
                    add +91. Only submit your 10 digit mobile number)
                  </p>
                </label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="w-full border-gray-200 px-4 border rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 focus:outline-none"
                  placeholder="9810078630 (Prefer WhatsApp number)"
                />
              </div>

              <div>
                <label className="block text-base font-medium text-black mb-1">
                  First Name of Person for whom the Songs are. The Name of the
                  Person you are making the Songs for{" "}
                  <strong>(Only first name, no surname)</strong>
                  <p className="text-sm  text-gray-500">
                    (Only First Name. No Surname. No Pet Name. Only First Name
                    as in Aadhar Card or Passport or Driving License)
                  </p>
                </label>
                <input
                  type="text"
                  name="dedicationName"
                  value={formData.dedicationName}
                  onChange={handleInputChange}
                  className="w-full border-gray-200 px-4 border rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 focus:outline-none"
                  placeholder="Priya, Sagar, Avani, Aryan"
                  maxLength={30}
                />
              </div>

              <div>
                <label className="block text-base font-medium text-black mb-2 ">
                  Language
                </label>
                <div className="flex space-x-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="language"
                      value="hindi"
                      checked={formData.language === "hindi"}
                      onChange={handleInputChange}
                      className="mr-2 focus:ring-red-500 cursor-pointer"
                    />
                    Hindi
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="language"
                      value="english"
                      checked={formData.language === "english"}
                      onChange={handleInputChange}
                      className="mr-2 focus:ring-red-500 cursor-pointer"
                    />
                    English
                  </label>
                </div>
              </div>

              <div>
                <label className="block text-base font-medium text-black mb-2">
                  Gender of Singer's Voice
                  <p className="text-sm  text-gray-500 ">
                    {" "}
                    (Most songs will be in this voice)
                  </p>
                </label>
                <div className="flex space-x-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="gender"
                      value="male"
                      checked={formData.gender === "male"}
                      onChange={handleInputChange}
                      className="mr-2 focus:ring-red-500 cursor-pointer"
                    />
                    Male Voice
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="gender"
                      value="female"
                      checked={formData.gender === "female"}
                      onChange={handleInputChange}
                      className="mr-2 focus:ring-red-500 cursor-pointer"
                    />
                    Female Voice
                  </label>
                </div>
              </div>
            </div>

            <div className="relative" ref={dropdownRef}>
              <label className="block text-base font-medium text-black">
                Characteristics of Person for who the song is (Minimum 3 upto
                10)
                <span className="text-sm text-gray-500">
                  <span className="text-gray-800 font-semibold ml-1">
                    {" "}
                    {formData.characteristics.length}
                  </span>{" "}
                  : Selected
                </span>
              </label>
              <div className="relative mt-2">
                <button
                  type="button"
                  className="w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none focus:ring-1 focus:border-black sm:text-sm"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  {formData.characteristics.length > 0
                    ? formData.characteristics.join(", ")
                    : "Select characteristics"}
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06 0L10 10.94l3.71-3.73a.75.75 0 011.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 010-1.06z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </button>

                {dropdownOpen && (
                  <div className="absolute z-10 mt-2 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    {characteristics.map((char) => (
                      <label
                        key={char}
                        className="flex items-center px-4 py-2 hover:bg-gray-100"
                      >
                        <input
                          type="checkbox"
                          value={char}
                          checked={formData.characteristics.includes(char)}
                          onChange={handleCheckboxChange}
                          className="mr-2"
                        />
                        {char}
                      </label>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div>
              <label className="block text-base font-medium text-black mb-3">
                Add Image{" "}
                <span>
                  {selectedOption === 2 && (
                    <strong>
                      (You can upload a minimum of 2 images and a maximum of 10.
                      Please make sure the images are not upside down.)
                    </strong>
                  )}
                </span>
                <p className="text-sm text-gray-500">
                  (If you add an image, we will deliver a video of any one song
                  with this image, which can be shared on Insta / WhatsApp /
                  other social media platforms. It's best to add a lovely
                  picture of the two of you.)
                </p>
              </label>

              <label
                className="rounded-md text-sm cursor-pointer bg-gradient-to-r from-red-500 to-pink-500 text-white"
                style={{
                  height: "8px",
                  width: "100px",
                  backgroundColor: "grey",
                  padding: "6px 8px",
                }}
              >
                Choose File
                <input
                  type="file"
                  id="fileInput"
                  name="image"
                  accept="image/*"
                  onChange={handleImageChange}
                  multiple={selectedOption === 2}
                  className="hidden"
                />
              </label>
              <div
                className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-6 gap-4 mt-4 overflow-x-auto max-h-40"
                style={{ scrollbarWidth: "thin" }}
              >
                {Array.isArray(previewImage) && previewImage.length > 0 ? (
                  previewImage.map((image, index) => (
                    <div key={index} className="relative group">
                      <img
                        src={image}
                        alt={`Preview ${index + 1}`}
                        className="w-20 h-20 sm:w-24 sm:h-24 md:w-32 md:h-32 rounded-md shadow-md object-cover"
                      />
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleRemoveImage(index);
                        }}
                        className="absolute top-0 right-0 h-7 w-7 bg-red-500 text-white rounded-full p-1 shadow-md opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        ✕
                      </button>
                    </div>
                  ))
                ) : (
                  <p className="col-span-6 text-center text-gray-500">
                    No images selected
                  </p>
                )}
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <input
                className="w-4 h-4 accent-[#ee4663]"
                id="term_and_conditions"
                type="checkbox"
                checked={checkTermsAndConditions}
                onClick={() =>
                  setCheckTermsAndConditions(!checkTermsAndConditions)
                }
              />
              <label
                className=" text-sm font-medium text-gray-700"
                htmlFor="term_and_conditions"
              >
                I agree to the{" "}
                <span
                  onClick={() =>
                    openModalWithComponent(<ValentineTermAndCondition />)
                  }
                  className="underline cursor-pointer text-blue-800"
                >
                  Terms and Conditions
                </span>
              </label>
            </div>
            {/* <div
              className="flex items-center gap-2"
              style={{ marginTop: "2px !important" }}
            >
              <RiCoupon3Fill className="text-green-800 text-3xl  max-md:text-5xl " />
              <span>
                If you have a coupon code, please enter it during checkout after
                submitting this form
              </span>
            </div> */}
            <div className="flex justify-center space-x-4">
              {/* <button
                type="submit"
                className={`w-full bg-gradient-to-r from-red-500 to-pink-500 text-white py-4 md:py-3 px-6 rounded-xl text-base md:text-lg font-semibold shadow-lg ${
                  loading ? "opacity-70 cursor-not-allowed" : "hover:scale-105"
                } hover:shadow-xl transition duration-300 glow-effect`}
                disabled={loading}
              >
                {loading ? "Processing..." : "Make Payment"}
              </button> */}
              <button
                type="button"
                onClick={handlePreview}
                className={`w-full bg-gradient-to-r from-red-500 to-pink-500 text-white py-4 md:py-3 px-6 rounded-xl text-base md:text-lg font-semibold shadow-lg ${loading ? "opacity-70 cursor-not-allowed" : "hover:scale-105"
                  } hover:shadow-xl transition duration-300 glow-effect`}
                disabled={loading}
              >
                {loading ? "Processing..." : "Preview Form"}
              </button>
            </div>
            {showFormDetails && (
              <div className="fixed inset-0 flex justify-center items-center text-black bg-black bg-opacity-50 w-full ">
                <div
                  className="relative rounded-lg p-2 sm:p-6 w-full sm:w-3/4 md:w-1/2 max-h-[90vh] overflow-y-auto bg-white shadow-xl  overflow-x-hidden "
                  ref={modalRef}
                  style={{
                    maxWidth: "90%",
                    maxHeight: "80vh",
                    scrollbarWidth: "thin",
                  }}
                >
                  {/* Close Button */}
                  <button
                    onClick={() => setShowFormDetails(false)}
                    className="absolute  -top-1 -right-1 p-2  text-black rounded-full hover:bg-pink-700 focus:outline-none transition duration-300 ease-in-out"
                    aria-label="Close"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                  <div className="bg-gradient-to-br from-pink-50 to-white p-2">
                    <div className="max-w-4xl mx-auto">
                      {/* Header */}
                      <div className="text-center mb-4">
                        <motion.h1
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 0.6 }}
                          className="text-2xl font-bold text-pink-600 mb-2"
                        >
                          Confirm your details
                        </motion.h1>
                      </div>

                      {/* Main Card */}
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1 }}
                        className="px-1 md:p-8  border-2 border-pink-100"
                      >
                        {/* Introduction */}
                        {/* Step by Step Guide */}
                        <div className="space-y-12">
                          {/* Personal Information Section */}
                          {/* <section> */}
                          <div className="flex justify-center flex-col mt-2">
                            <div className="space-y-2">
                              {/* <h3 className="font-medium text-pink-600">
                                  Name Fields
                                </h3> */}
                              <div className="bg-white p-4 rounded border border-pink-100">
                                <p className="text-gray-700">
                                  First Name:{" "}
                                  <span className="font-bold">
                                    {formData.firstName}
                                  </span>
                                </p>
                                <p className="text-gray-700">
                                  Last Name:{" "}
                                  <span className="font-bold">
                                    {formData.lastName}
                                  </span>
                                </p>
                                <p className="text-gray-700">
                                  City:{" "}
                                  <span className="font-bold">
                                    {formData.city}
                                  </span>
                                </p>
                                <p className="text-gray-700">
                                  Email:{" "}
                                  <span className="font-bold">
                                    {formData.email}
                                  </span>
                                </p>
                                <p className="text-gray-700">
                                  Mobile:{" "}
                                  <span className="font-bold">
                                    {formData.phone}
                                  </span>
                                </p>
                                <p className="text-gray-700">
                                  Name of the Person you are making the Songs
                                  for:{" "}
                                  <span className="font-bold">
                                    {formData.dedicationName}
                                  </span>
                                </p>
                                <p className="text-gray-700 capitalize">
                                  Language Options:{" "}
                                  <span className="font-bold">
                                    {formData.language}
                                  </span>
                                </p>
                                <p className="text-gray-700 capitalize">
                                  Voice Selection:{" "}
                                  <span className="font-bold">
                                    {formData.gender}
                                  </span>
                                </p>
                                <p className="text-gray-700 capitalize">
                                  Characteristics of Person:{" "}
                                  <span className="font-bold">
                                    {formData?.characteristics?.length > 0
                                      ? formData.characteristics.join(", ")
                                      : "N/A"}
                                  </span>
                                </p>
                                <p className="text-gray-700 capitalize">
                                  Uploaded Images:{" "}
                                  {Array.isArray(previewImage) &&
                                    previewImage.length > 0 ? (
                                    <div className="flex gap-2 overflow-x-auto p-2 mt-2">
                                      {previewImage.map((image, index) => (
                                        <div
                                          key={index}
                                          className="flex-shrink-0"
                                        >
                                          <img
                                            src={image}
                                            alt={`Preview ${index + 1}`}
                                            className="w-20 h-20 sm:w-24 sm:h-24 md:w-32 md:h-32 rounded-md shadow-md object-cover"
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  ) : (
                                    <span className="font-bold">
                                      No images selected
                                    </span>
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* </section> */}
                        </div>
                        <p className="text-gray-700 mt-4 font-extrabold">
                          ⚠️ Once the payment is completed, you cannot modify
                          any details or replace images or add new images.
                        </p>
                        <div className="flex flex-col sm:flex-row justify-between mt-6 gap-3 mb-2">
                          <button
                            className="bg-gray-400 text-white py-2 px-4 rounded-lg hover:bg-gray-500 transition duration-300"
                            onClick={() => setShowFormDetails(false)}
                            disabled={loading}
                          >
                            ✏️ Edit Form
                          </button>
                          <button
                            className="bg-gradient-to-r from-red-500 to-pink-500 text-white py-2 px-4 rounded-lg font-semibold shadow-md hover:scale-105 hover:shadow-lg transition duration-300"
                            onClick={handleSubmit}
                            // onClick={handlehold}
                            disabled={loading}
                          >
                            {loading
                              ? "Processing..."
                              : "Confirm & Make Payment"}
                          </button>
                        </div>
                      </motion.div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative rounded-lg p-0 shadow-lg max-w-sm w-full text-center">
            <svg
              className="relative h-24 w-24 animate-beat mx-auto"
              fill="#ee4669"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 475.528 475.528"
            >
              <g id="SVGRepo_iconCarrier">
                <g>
                  <g>
                    <path d="M237.376,436.245l0.774,0.976c210.94-85.154,292.221-282.553,199.331-367.706 c-92.899-85.154-199.331,30.953-199.331,30.953h-0.774c0,0-106.44-116.107-199.331-30.953 C-54.844,154.658,26.437,351.092,237.376,436.245z"></path>
                  </g>
                </g>
              </g>
            </svg>

            <p className="text-white mt-4 text-lg font-semibold">
              Processing...
            </p>
          </div>
          <style>
            {`
        @keyframes beat {
          0%, 100% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.2);
          }
        }
        .animate-beat {
          animation: beat 0.6s infinite ease-in-out;
        }
      `}
          </style>
        </div>
      )}

      {showHowToUse && (
        <div className="fixed inset-0 flex justify-center items-center text-black bg-black bg-opacity-50 w-full ">
          <div
            className="relative rounded-lg p-2 sm:p-6 w-full sm:w-3/4 md:w-1/2 max-h-[90vh] overflow-y-auto bg-white shadow-xl  overflow-x-hidden "
            ref={modalRef}
            style={{
              maxWidth: "90%",
              maxHeight: "80vh",
              scrollbarWidth: "thin",
            }}
          >
            {/* Close Button */}
            <button
              onClick={() => setShowHowToUse(false)}
              className="absolute  -top-1 -right-1 p-2  text-black rounded-full hover:bg-pink-700 focus:outline-none transition duration-300 ease-in-out"
              aria-label="Close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            {/* Form Content */}
            <ValantineGuideForm />
          </div>
        </div>
      )}
      {checkHandleHold && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90">
          <div className="relative bg-white rounded-xl p-6 shadow-lg w-80">
            {/* Close Button */}
            <button
              onClick={() => setCheckHandleHold(false)}
              className="absolute top-2 right-2 text-red-500 hover:text-gray-800"
            >
              ✖
            </button>

            {/* Message */}
            <p className="text-center text-gray-700 text-xl font-semibold">
              We’re unable to take new orders for the next 2 hours due to high
              demand. Please try again after 2 hours.
            </p>
          </div>
        </div>
      )}
      {isPdfModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50 shadow-md">
          <div
            className=" rounded-lg px-2 relative max-w-7xl"
            style={{ height: "100%" }}
          >
            <button
              className="absolute top-1 right-2 text-white text-xl hover:text-black "
              onClick={closepdfModal}
            >
              ✖
            </button>
            <div className="mt-8 overflow-auto h-[800px]">
              {/* Render the dynamic component */}
              {currentComponent}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ValentineForm;
