import React, { useEffect, useState } from "react";
import { FaCirclePause, FaCirclePlay, FaDownload } from "react-icons/fa6";
import { useParams, useNavigate } from "react-router-dom";
import showNotification from "../../../src/hooks/notificationHook.js";
import loader from "../../assets/dashboard/valentineLoader.gif";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import JSZip from "jszip";
import { motion } from "framer-motion";
import { FiDownload } from "react-icons/fi";
import apiUrl from "../../services/api.jsx";

function ValentinesDelivery() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [valentine, setValentine] = useState("user");
  const [currentAudio, setCurrentAudio] = useState(null);
  const [valentineData, setValentineData] = useState([]);
  const [valentineUser, setValentineUser] = useState("user");
  const [playingSongId, setPlayingSongId] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [inProcess, setInProcess] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [responseImage, setResponseImage] = useState("");
  const [responseVideo, setResponseVideo] = useState("");
  const [audioLanguages, setAudioLanguages] = useState("");

  const defaultImage =
    "https://imas-valentine.s3.eu-central-003.backblazeb2.com/General-images/couples_heart-image.png";

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        apiUrl.ValentinesDelivery.replace("{id}", id),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      // console.log("ddd", data);
      let video = "";
      if (
        data.message === "No data found for the given form_id." ||
        data.message === "Invalid form_id provided."
      ) {
        setInProcess(true);
        setValentine("");
        setResponseMessage(data.message);
      } else {
        const updatedData = data?.audio_data?.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setValentine(data.name);
        setResponseImage(data.image_url);
        video = data?.video_data?.video_url ? data?.video_data?.video_url : "";
        setAudioLanguages(data?.language ? data?.language : "english");
        setResponseVideo(video);
        updatedData && setValentineData(updatedData);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  
  const downloadVideo = (videoUrl, videoTitle = "Valentine_video") => {
    setIsLoading(true);
    fetch(videoUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${videoTitle}.mp4`;

        // Appending the link and triggering the click
        document.body.appendChild(a);
        a.click();

        // Cleanup
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url); // Revoke the object URL
        showNotification(
          "success",
          "Your video has been successfully downloaded!"
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error downloading video:", error);
        setIsLoading(false);
      });
  };

  const handlePlayPause = (songAudioURL, songId) => {
    if (currentAudio && playingSongId === songId) {
      currentAudio.pause();
      setPlayingSongId(null);
      return;
    }

    if (currentAudio) {
      currentAudio.pause();
    }

    const audio = new Audio(songAudioURL);
    audio.play();
    setCurrentAudio(audio);
    setPlayingSongId(songId);

    audio.onloadedmetadata = () => {
      setDuration(audio.duration);
    };

    audio.ontimeupdate = () => {
      setCurrentTime(audio.currentTime);
    };
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    // Ensure two digits for seconds
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleSeek = (event) => {
    // const newTime =
    //   (event.nativeEvent.offsetX / event.target.offsetWidth) * duration;
    // currentAudio.currentTime = newTime;
    // setCurrentTime(newTime);

    if (!currentAudio || !currentAudio.duration) return;

    const progressBar = event.currentTarget; 
    const progressBarWidth = progressBar.offsetWidth;
    const clickX = event.nativeEvent.offsetX; 
  
    const newTime = (clickX / progressBarWidth) * currentAudio.duration;
  
    currentAudio.currentTime = newTime;
    setCurrentTime(newTime);
  };

  // Function to download all songs as a zip
  const downloadAllSongs = () => {
    // showNotification(
    //   "success",
    //   "These are sample songs and cannot be downloaded."
    // );

    setIsLoading(true); // Show loader while processing
    const zip = new JSZip(); // Create a new zip object
    // const songFiles = valentineData.filter((song) => song.Name === valentine); // Get all the songs for the current user
    const songFiles = valentineData; // Get all the songs for the current user
    // Function to fetch a song and add it to the zip
    const fetchAndAddSongToZip = async (song, i, retries = 3) => {
      const audioURL = song.audio_url;
      const fileName = `${song.title ? song.title : `Valentine_song_${i}`}.mp3`; // You can adjust the file extension based on the actual audio file format
      try {
        const response = await fetch(audioURL);
        if (!response.ok) {
          throw new Error("Failed to fetch song: " + response.statusText);
        }
        const blob = await response.blob();
        zip.file(fileName, blob); // Add the song file to the zip
      } catch (err) {
        console.error(`Error fetching song ${song.title}:`, err);

        // Retry if there are retries left
        if (retries > 0) {
          console.log(`Retrying... (${3 - retries + 1})`);
          return fetchAndAddSongToZip(song, retries - 1); // Retry the fetch
        } else {
          console.error(`Failed to fetch song after retries: ${song.Title}`);
        }
      }
    };

    // Sequentially download and add each song to the ZIP
    const addAllSongsToZip = async () => {
      for (let i = 0; i < songFiles.length; i++) {
        await fetchAndAddSongToZip(songFiles[i], i + 1); // Wait for each song to be added
      }

      // After all songs are added, generate the ZIP file
      try {
        const content = await zip.generateAsync({ type: "blob" });
        const a = document.createElement("a");
        const name = `Love Album of ${valentine}`;
        a.href = URL.createObjectURL(content);
        a.download = `${name}.zip`;
        a.click(); // Start the download

        setIsLoading(false); // Hide the loader once the download starts
        showNotification(
          "success",
          "Your album has been successfully downloaded!"
        );
      } catch (error) {
        console.error("Error generating zip file:", error);
        setIsLoading(false); // Hide the loader in case of error
      }
    };

    // Start adding songs to the zip
    addAllSongsToZip();
  };

  const handleBack = () => {
    if (currentAudio) {
      currentAudio.pause();
      setPlayingSongId(null);
      setCurrentAudio(null);
    }
    navigate("/valentines");
  };

  const handleDownload = async (url, filename = "audio.mp3") => {
    try {
      setIsLoading(true);

      // Fetch the file as a Blob
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.statusText}`);
      }

      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = filename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(blobUrl);

      showNotification(
        "success",
        "Your song has been successfully downloaded!"
      );
    } catch (error) {
      console.error("Error downloading file:", error);
      showNotification("error", "Failed to download the song.");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen text-white">
        <img src={loader} alt="loader" className="h-40" />
      </div>
    );
  }

  return (
    <div className="valentines-gradient min-h-screen text-white p-4">
      {/* Header */}
      <div className="flex items-center mb-6">
        <button
          onClick={handleBack}
          className="text-left text-2xl mr-1 font-bold"
          aria-label="Go back"
        >
          <IoArrowBackCircleOutline size={32} />
        </button>
        <h1 className="text-center flex-1 text-xl font-semibold">
          {`Valentines Song ${valentine && " for " + valentine}`}
        </h1>
      </div>

      {/* Playlist Cover */}
      <div className="relative flex flex-col items-center">
        {/* Image */}
        <div className="relative">
          <img
            src={
              responseImage
                ? responseImage
                : "https://imas-valentine.s3.eu-central-003.backblazeb2.com/General-images/couples_heart-image.png"
            }
            alt="Happy Valentine's Day"
            className="rounded-lg h-72 valentine-couple-img"
          />
          {/* Overlay Text */}
          <div
            className={`absolute ${
              responseImage !== defaultImage && responseImage
                ? ` items-end`
                : "left-7 top-16 items-center"
            } inset-0 flex  justify-center`}
          >
            <motion.p
              className="text-center text-2xl font-bold px-4 py-2 rounded-lg bg-gradient-to-r from-white via-red-100 to-red-500 bg-clip-text text-transparent"
              style={{
                WebkitTextStroke: "0.5px red",
                textStroke: "0.5px red",
              }}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{
                duration: 1,
                ease: "easeOut",
              }}
            >
              Happy Valentine's <br /> {valentine}
            </motion.p>
            {/* Sparkles */}
            <div className="absolute inset-0 pointer-events-none">
              {[...Array(15)].map((_, index) => (
                <motion.div
                  key={index}
                  className="absolute text-pink-500"
                  style={{
                    fontSize: `${Math.random() * 12 + 8}px`,
                    top: `${Math.random() * 100}%`,
                    left: `${Math.random() * 100}%`,
                  }}
                  animate={{
                    opacity: [0, 1, 0],
                    scale: [0, 1.5, 0],
                    y: [0, -10, -20],
                  }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    delay: Math.random() * 2,
                  }}
                >
                  ♥
                </motion.div>
              ))}
            </div>
          </div>
        </div>
        {/* Additional Info */}
        <p className="text-sm text-gray-400 mt-4">
          Cover - Romantic, Valentines
        </p>
        {valentine && (
          <p className="mt-2 text-sm text-green-400">Made for {valentine}</p>
        )}
      </div>

      {/* Audio Progress Bar */}
      {playingSongId && (
        <div className="mt-6 px-8">
          <p className="text-sm text-center mb-2 text-gray-400">
            {formatTime(currentTime)} / {formatTime(duration)}
          </p>
          <div
            className="w-full h-2 bg-gray-700 rounded-md cursor-pointer"
            onClick={handleSeek}
          >
            <div
              className="h-2 bg-red-800 rounded-md"
              style={{ width: `${(currentTime / duration) * 100}%` }}
            />
          </div>
        </div>
      )}
      {!inProcess ? (
        <>
          {/* Video Section */}
          <div className="mt-2">
            {responseVideo && (
              <div
                // key={song.id}
                className="flex items-center justify-between px-4 py-3 "
              >
                <div className="flex items-center">
                  <img
                    src="https://imas-valentine.s3.eu-central-003.backblazeb2.com/General-images/valentines_heart.png"
                    alt="theme-icon"
                    className="w-12 h-12 rounded-md mr-4"
                  />
                  <div>
                    <p className="font-medium">
                      {audioLanguages === "english"
                        ? "Valentine Video"
                        : "वैलेंटाइन वीडियो"}
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <button
                    className="text-white bg-red-600 hover:bg-red-800 text-sm py-2 px-4 rounded-full"
                    onClick={() => downloadVideo(responseVideo)}
                  >
                    <div className="flex">
                      <FaDownload className="mr-2" />
                      Video
                    </div>
                  </button>
                </div>
              </div>
            )}
          </div>

          <hr className="w-[100%] mx-auto my-1 border-t-1 border-gray-700" />

          {/* Song List */}
          <div className="mt-2">
            {valentineData.map((song, i) => (
              <div
                key={song.id}
                className="flex items-center justify-between px-4 py-3 border-b border-gray-700"
              >
                <div className="flex items-center">
                  <img
                    src="https://imas-valentine.s3.eu-central-003.backblazeb2.com/General-images/valentines_heart.png"
                    alt="theme-icon"
                    className="w-12 h-12 rounded-md mr-4"
                  />
                  <div>
                    {/* <p className="font-medium">{`Valentine Song ${i + 1}`}</p> */}
                    <p className="font-medium">
                      {song.title ? song.title : "Valentine Song"}
                    </p>
                    {/* <p className="text-sm text-gray-400">{song.artist}</p> */}
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  <button
                    className="text-gray-400 hover:text-white text-3xl p-2 rounded-full"
                    onClick={() => handlePlayPause(song.audio_url, song.id)}
                  >
                    {playingSongId === song.id ? (
                      <FaCirclePause />
                    ) : (
                      <FaCirclePlay />
                    )}
                  </button>
                  <button
                    className=" text-3xl p-2 rounded-full"
                    onClick={() =>
                      handleDownload(
                        song.audio_url,
                        `${
                          song.title ? song.title : `Valentine_song_${i + 1}`
                        }.mp3`
                      )
                    }
                  >
                    {/* <FiDownload className="text-grey-400 hover:text-white" size={29} /> */}
                    <FaDownload className="text-[#ea003b]" size={24} />
                  </button>
                </div>
              </div>
            ))}
          </div>
          {/* Download All Button */}
          {valentineData.length > 0 && (
            <div className="mt-6 flex justify-center mb-10">
              <button
                onClick={downloadAllSongs} // Trigger the download when the button is clicked
                className="bg-red-600 hover:bg-red-700 text-white px-6 py-1 rounded-full text-lg shadow-md"
              >
                Download All Songs
              </button>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="bg-black text-center mt-6 p-1 mb-2">
            {responseMessage === "No data found for the given form_id." ? (
              <p className="text-lg text-gray-400">
                Your request is still in process...
              </p>
            ) : (
              <p className="text-lg text-gray-400">Invalid user request</p>
            )}
          </div>
        </>
      )}

      <footer className="bg-black text-center">
        <p className="text-sm text-gray-400">
          This Love Album was created especially for you at{" "}
          <a
            href="https://www.imadeasong.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-green-500"
          >
            www.imadeasong.com
          </a>
        </p>
      </footer>
    </div>
  );
}

export default ValentinesDelivery;
