import React, { useState } from "react";
import { motion } from "framer-motion";

function ValatineGuideForm() {
  return (
    <div className="bg-gradient-to-br from-pink-50 to-white p-2">
      <div className="max-w-4xl mx-auto">
        {/* Header */}
        <div className="text-center mb-4">
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6 }}
            className="text-2xl font-bold text-pink-600 mb-2"
          >
            How to Fill Your Valentine's Song Request
          </motion.h1>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="mt-2"
          >
            Follow this simple guide to create the perfect personalized song
          </motion.p>
        </div>

        {/* Main Card */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="px-1 md:p-8  border-2 border-pink-100"
        >
          {/* Introduction */}
          <section className="mb-6">
            <div className="bg-pink-50 md:p-6 rounded-lg">
              <motion.h2
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.8 }}
                className="text-2xl font-semibold text-pink-600 mb-4"
              >
                Before You Begin
              </motion.h2>
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                className="text-gray-600"
              >
                Please have the following ready:
              </motion.p>
              <ul className="list-disc pl-6 mt-2 space-y-2 text-gray-600">
                <motion.li
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.5, duration: 1 }}
                >
                  A clear photo of the person (if you wish to include one)
                </motion.li>
                <motion.li
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.6, duration: 1 }}
                >
                  Their key characteristics and personality traits
                </motion.li>
                <motion.li
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.7, duration: 1 }}
                >
                  Your preferred language for the song
                </motion.li>
                <motion.li
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.8, duration: 1 }}
                >
                  The singer's voice preference
                </motion.li>
              </ul>
            </div>
          </section>

          {/* Step by Step Guide */}
          <div className="space-y-12">
            {/* Personal Information Section */}
            <section>
              <motion.h2
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.3, duration: 1 }}
                className="text-2xl font-semibold text-pink-600 mb-6 flex items-center gap-2"
              >
                <span className="bg-pink-100 rounded-full w-8 h-8 flex items-center justify-center text-lg">
                  1
                </span>
                Your Information
              </motion.h2>
              <div className="space-y-4 pl-10">
                <div className="space-y-2">
                  <h3 className="font-medium text-pink-600">Name Fields</h3>
                  <div className="bg-white p-4 rounded border border-pink-100">
                    <p className="text-sm text-gray-500">Example:</p>
                    <p className="text-gray-700">First Name: John</p>
                    <p className="text-gray-700">Last Name: Smith</p>
                  </div>
                </div>
                <div className="space-y-2">
                  <h3 className="font-medium text-pink-600">Contact Details</h3>
                  <div className="bg-white p-4 rounded border border-pink-100">
                    <p className="text-gray-700">City: Pune</p>
                    <p className="text-gray-700">Email: john.smith@email.com</p>
                    <p className="text-gray-700">Mobile: 9810078630</p>
                  </div>
                </div>
              </div>
            </section>

            {/* Recipient Information */}
            <section>
              <motion.h2
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.4, duration: 1 }}
                className="text-2xl font-semibold text-pink-600 mb-6 flex items-center gap-2"
              >
                <span className="bg-pink-100 rounded-full w-8 h-8 flex items-center justify-center text-lg">
                  2
                </span>
                First Name of Person for whom the Songs are
              </motion.h2>
              <div className="space-y-4 pl-10">
                <div className="bg-white p-4 rounded border border-pink-100">
                  <p className="text-sm text-gray-500">✅ Correct Example:</p>
                  <p className="text-gray-700">Name: Sarah</p>
                  <p className="text-sm text-gray-500 mt-2">
                    ❌ Incorrect Example:
                  </p>
                  <p className="text-gray-700 line-through">
                    Name: Sarah Johnson
                  </p>
                </div>
              </div>
            </section>

            {/* Song Preferences */}
            <section>
              <motion.h2
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.5, duration: 1 }}
                className="text-2xl font-semibold text-pink-600 mb-6 flex items-center gap-2"
              >
                <span className="bg-pink-100 rounded-full w-8 h-8 flex items-center justify-center text-lg">
                  3
                </span>
                Song Preferences
              </motion.h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 ">
                <div className="bg-white p-4 rounded border border-pink-100">
                  <h3 className="font-medium text-pink-600 mb-2">
                    Language Options
                  </h3>
                  <ul className="space-y-1 text-gray-700">
                    <li>• English</li>
                    <li>• Hindi</li>
                  </ul>
                </div>
                <div className="bg-white p-4 rounded border border-pink-100">
                  <h3 className="font-medium text-pink-600 mb-2">
                    Voice Selection
                  </h3>
                  <ul className="space-y-1 text-gray-700">
                    <li>• Male Voice</li>
                    <li>• Female Voice</li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Final Tips */}
            <section className="mt-6 bg-pink-50 p-6 rounded-lg">
              <h2 className="text-xl font-semibold text-pink-600 mb-4">
                💝 Final Tips for Success
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="bg-white p-4 rounded-lg">
                  <p className="text-gray-600">✓ Double-check all spellings</p>
                  <p className="text-gray-600">✓ Be specific with traits</p>
                </div>
                <div className="bg-white p-4 rounded-lg">
                  <p className="text-gray-600">
                    ✓ Choose meaningful characteristics
                  </p>
                  <p className="text-gray-600">
                    ✓ Take your time filling the form
                  </p>
                </div>
              </div>
            </section>
          </div>
        </motion.div>

        {/* Footer */}
        <footer className="text-center mt-8 space-y-2">
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 1 }}
            className="text-pink-600"
          >
            Need help? We're here for you!
          </motion.p>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.2, duration: 1 }}
            className="text-gray-500"
          >
            <a href="mailto: support@imadeasong.com">support@imadeasong.com</a>
          </motion.p>
        </footer>
      </div>
    </div>
  );
}

export default ValatineGuideForm;
