import React, { useEffect, useState } from "react";
import { FaPlay, FaRegPlayCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import fnpImg from "../../assets/valentineImages/83609_Large.jpg";
import firstBanner from "../../assets/valentineImages/Banner.jpg";
import Img1 from "../../assets/valentineImages/ComfyUI_00165_.webp";
import Img2 from "../../assets/valentineImages/ComfyUI_00184_.webp";
import Img3 from "../../assets/valentineImages/ComfyUI_00190_.webp";
import Img4 from "../../assets/valentineImages/ComfyUI_00194_.webp";
import heartSideImg from "../../assets/valentineImages/H1.png";
import slideShowImg from "../../assets/valentineImages/I1.png";
import sideImg1 from "../../assets/valentineImages/M1.png";
import mobileImg from "../../assets/valentineImages/MOBILE.png";
import mobileImg1 from "../../assets/valentineImages/MOBILE1.png";
import img5 from "../../assets/valentineImages/Play-the-song-during-a-romantic-ezgif.com-optiwebp.webp";
import img6 from "../../assets/valentineImages/Play-the-song-on-a-scenic-romantic-long-drive.-ezgif.com-optiwebp.webp";
import img7 from "../../assets/valentineImages/Use-the-song-as-part-of-your-proposal-moment.-ezgif.com-optiwebp.webp";
import tripImg from "../../assets/valentineImages/easemytrip-removebg-preview.png";
import marvelImg from "../../assets/valentineImages/marvelstudios.png";
import netflixImg from "../../assets/valentineImages/netfli.png";
import priceBannerImg from "../../assets/valentineImages/pricebanner.png";
import primeImg from "../../assets/valentineImages/primevideo.png";
// second grid
import axios from "axios";
import { motion } from "framer-motion";
import moment from "moment/moment.js";
import { useForm } from "react-hook-form";
import { FaWhatsapp } from "react-icons/fa";
import { IoCloseCircleOutline } from "react-icons/io5";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import heartIcon from "../../assets/valentineImages/Beating-Heart-Emoji-icon.png";
import imgBrand from "../../assets/valentineImages/brandpratner.png";
import img12 from "../../assets/valentineImages/couple-sitting-floor-with-balloons-hand-nose-with-nose1-ezgif.com-optiwebp.webp";
import img10 from "../../assets/valentineImages/handwritten-note1-ezgif.com-optiwebp.webp";
import img11 from "../../assets/valentineImages/young-love-asian-couple-holding-valentine-s-greeting-card-ezgif.com-optiwebp.webp";
import NotificationPopup from "../../components/NotificationPopup.jsx";
import valentineNotification from "../../hooks/valentineNotification";
import apiUrl from "../../services/api";
import ValentineCancellationPolicy from "./valentineTermAndConditions/ValentineCancellationPolicy";
import ValentinePrivacyPolicy from "./valentineTermAndConditions/ValentinePrivacyPolicy";
import ValentineTermAndCondition from "./valentineTermAndConditions/ValentineTermAndCondition";

function ValentinePage() {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [currentComponent, setCurrentComponent] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm();

  // const getNextTargetTime = () => {
  //   const now = moment();
  //   const targetTime = moment("2025-02-13 21:00:00")
  //   let sections = [];

  //   // if (now.isBetween(moment("2025-02-04"), moment("2025-02-07"), null, "[)")) {
  //   //   sections = [0, 6, 12, 18];
  //   // } else if (
  //   //   now.isBetween(moment("2025-02-07"), moment("2025-02-09"), null, "[)")
  //   // ) {
  //   //   // sections = [0, 4, 8, 12, 16, 20];
  //   //   sections = [0, 12];
  //   // } else if (
  //   //   now.isBetween(moment("2025-02-09"), moment("2025-02-13"), null, "[)")
  //   // ) {
  //   //   // sections = [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22];
  //   //   sections = [0, 12];
  //   // } else {
  //   //   return now;
  //   // }

  //   const currentHour = now.hour();
  //   let nextSection = sections.find((hour) => currentHour < hour);
  //   if (nextSection === undefined) {
  //     nextSection = sections[0];
  //     now.add(1, "day");
  //   }

  //   return now.startOf("day").add(nextSection, "hours");
  // };

  const getNextTargetTime = () => {
    const now = moment();

    // Set the target time to 9 PM on February 13th
    const targetTime = moment("2025-02-14 22:00:00");
    // If the current time is after the target time (meaning it's already 9 PM on 13th or later),
    // set the target time to the next day, same time (9 PM).
    if (now.isAfter(targetTime)) {
      return targetTime.add(1, "days"); // Move to the next day's 9 PM
    }
    return targetTime;
  };

  // const calculateTimeLeft = () => {
  //   const now = moment();
  //   const difference = moment.duration(targetTime.diff(now));
  //   if (difference.asMilliseconds() <= 0)
  //     return { hours: 0, minutes: 0, seconds: 0 };
  //   let hours = difference.hours();
  //   const minutes = difference.minutes();
  //   const seconds = difference.seconds();
  //   const period = hours >= 12 ? "PM" : "AM";
  //   // hours = hours % 12 || 12;
  //   hours = hours % 12;

  //   return { hours, minutes, seconds, period };
  // };

  const [targetTime, setTargetTime] = useState(getNextTargetTime());

  const calculateTimeLeft = () => {
    const now = moment();
    const difference = moment.duration(targetTime.diff(now));

    if (difference.asMilliseconds() <= 0)
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };

    // Get the difference in days, hours, minutes, and seconds
    const days = difference.days();
    let hours = difference.hours();
    const minutes = difference.minutes();
    const seconds = difference.seconds();

    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    if (
      timeLeft.days === 0 &&
      timeLeft.hours === 0 &&
      timeLeft.minutes === 0 &&
      timeLeft.seconds === 0
    ) {
      setTargetTime(getNextTargetTime());
    }
  }, [timeLeft]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetTime]);

  const testimonials = [
    {
      name: "Rohit Khanna",
      review:
        "My fiancé teared up listening to our love story in a song. Best surprise ever! Thank you!",
      stars: 5,
    },
    {
      name: "Megha Choudhury",
      review: "The perfect way to say ‘I love you.’ My husband was speechless!",
      stars: 5,
    },
    {
      name: "Kabir Sinha",
      review:
        "This song made my girlfriend’s day! She kept replaying it, smiling non-stop.",
      stars: 5,
    },
    {
      name: "Arjun Nair",
      review:
        "Being in a long-distance relationship is tough, but this song made us feel closer than ever. My fiancé keeps playing it, saying it makes him feel closer to me",
      stars: 5,
    },
    {
      name: "Tanya Mehta",
      review:
        "He hugged me tightly, speechless. The song brought out emotions we never expressed before. Unforgettable!",
      stars: 5,
    },
  ];

  useEffect(() => {
    if (isVideoModalOpen || isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isVideoModalOpen, isModalOpen]);

  const howToUseUrl =
    "https://imas-general.s3.eu-central-003.backblazeb2.com/445664674.mp4";

  const supriseUrl =
    "https://imas-general.s3.eu-central-003.backblazeb2.com/2025-02-07+at+6.36.56.mp4";
  const coupleUrl =
    "https://imas-general.s3.eu-central-003.backblazeb2.com/valentines_video.mp4";

  const handleVideoModalOpen = (url) => {
    setVideoSrc(url);
    setIsVideoModalOpen(true);
    setVideoUrl(url);

    // Determine the page URL based on the provided video URL
    const pageUrl =
      url === supriseUrl
        ? "/supriseUrl"
        : url === coupleUrl
          ? "/coupleUrl"
          : url === howToUseUrl
            ? "/howtouseUrl"
            : null;

    if (pageUrl) {
      axios
        .get(apiUrl.trackCountVideoURl, {
          params: { page_url: pageUrl },
        })
        .then((response) => { })
        .catch((error) => {
          console.error(`Error fetching ${pageUrl} data:`, error);
        });
    }
  };

  const handleVideoModalClose = () => {
    setIsVideoModalOpen(false);
    setVideoSrc("");
  };

  const websiteUrl = "https://www.imadeasong.com/valentines";

  const handleInviteFriends = (action) => {
    if (action === "whatsapp") {
      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
        `Hey! I just discovered this beautiful website where you can craft personalized love song album and heartfelt slideshow videos—like a beautiful gift for your loved ones this Valentine’s Day, perfect for sharing unforgettable moments together! : ${websiteUrl}`
      )}`;
      const link = document.createElement("a");
      link.href = whatsappUrl;
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      link.click();
    } else if (action === "copy") {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(websiteUrl)
          .then(() => {
            alert("Link copied to clipboard!");
          })
          .catch(() => {
            alert("Failed to copy. Please try again!");
          });
      } else {
        // Fallback for older browsers
        const tempInput = document.createElement("input");
        tempInput.value = websiteUrl;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        alert("Link copied to clipboard!");
      }
    }
  };

  useEffect(() => {
    const showModal = () => {
      const lastShownTime = localStorage.getItem("modalLastShown");
      const currentTime = new Date().getTime();

      if (!lastShownTime || currentTime - lastShownTime > 60 * 60 * 1000) {
        setIsModalVisible(true);
        localStorage.setItem("modalLastShown", currentTime);
      }
    };

    showModal();

    const interval = setInterval(() => {
      showModal();
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const closeModal = () => {
    setIsModalVisible(false);
  };

  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // }, []);

  const handleOrderNow = () => {
    setIsModalOpen(true); // Open the modal
  };

  const handleSelection = (value) => {
    setIsModalOpen(false);
    navigate("/valentine-form", { state: { selectedOption: value } });
  };

  const handleLoveSongAlbum = (value) => {
    navigate("/valentine-form", { state: { selectedOption: value } });
  };

  const handleSlideShowAlbum = (value) => {
    navigate("/valentine-form", { state: { selectedOption: value } });
  };

  const openModalWithComponent = (component) => {
    setIsPdfModalOpen(true);
    setCurrentComponent(component);
  };

  const closepdfModal = () => {
    setIsPdfModalOpen(false);
    setCurrentComponent(null);
  };

  const handleEmailInvite = async (data) => {
    setIsLoading(true);

    try {
      const response = await axios.post(apiUrl.invitationMail, data, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.data.success) {
        valentineNotification(
          "success",
          "Email sent successfully! Kindly check your inbox."
        );
        reset(); // Reset form fields
      } else {
        setError("email", {
          type: "manual",
          message: "Something went wrong. Please try again.",
        });
      }
    } catch (error) {
      setError("email", {
        type: "manual",
        message: "Something went wrong. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const TimeUnit = ({ value, label }) => (
    <motion.div
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
      className="flex flex-col items-center mx-4"
    >
      <motion.div
        key={value}
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className="text-3xl font-bold text-pink-600 mb-2"
      >
        {String(value).padStart(2, "0")}
      </motion.div>
      <div className="text-pink-400 text-lg font-dancing-script">{label}</div>
    </motion.div>
  );

  // const FloatingHeart = ({ delay }) => (
  //   <motion.div
  //     className="absolute text-2xl"
  //     initial={{ y: 2, x: Math.random() * 100 - 50, opacity: 0 }}
  //     animate={{
  //       y: -100,
  //       opacity: [0, 1, 0],
  //       x: Math.random() * 100 - 50,
  //     }}
  //     transition={{
  //       duration: 3,
  //       repeat: Infinity,
  //       delay: delay,
  //     }}
  //   >
  //     🎵
  //   </motion.div>
  // );

  const FloatingHeart = ({ delay }) => (
    <motion.div
      className="absolute text-2xl"
      initial={{ y: 50, x: Math.random() * 100 - 50, opacity: 0 }}
      animate={{
        y: -300, // Increased to move farther up
        opacity: [0, 1, 1, 0], // Ensures it stays visible longer
        x: Math.random() * 100 - 50,
      }}
      transition={{
        duration: 4, // Slightly slower for a smoother effect
        repeat: Infinity,
        ease: "easeOut",
        delay: delay,
      }}
    >
      🎵
    </motion.div>
  );
  return (
    <div
      className="bg-[#fff6f8] min-h-screen overflow-x-hidden "
      style={{ fontFamily: "'Segoe UI Emoji', sans-serif" }}
    >
      <div className="max-w-7xl mx-auto relative mb-20">
        <div
          className="bg-no-repeat min-h-screen flex flex-col items-center justify-center px-4 sm:px-6 bg-top rounded-b-[50px] md:bg-cover"
          style={{
            backgroundImage: `url(${firstBanner})`,
            backgroundSize: "cover",
            // backgroundPosition: "center", // Centers the image
          }}
        >
          {/* Header Section */}
          <div className="ml-2 mt-[24rem] sm:ml-4 sm:mt-60 md:mt-[51rem]">
            <h1
              className="text-2xl max-md:text-3xl sm:text-3xl md:text-5xl  text-[#590812]  font-Centaur text-center"
              style={{ fontFamily: "Centaur" }}
            >
              The Most Romantic Gift for Your Valentine
            </h1>
            <div className="bg-white rounded-xl px-3 py-2 sm:px-5 sm:py-4 mt-4 ">
              <p className="text-sm sm:text-md md:text-xl text-pink-600 font-serif text-center">
                Introducing The World’s First Personalized
                <p className="font-semibold">Valentine’s Love Song Album!</p>
              </p>
            </div>
          </div>

          {/* Description Section */}
          <div
            className="text-center mt-10 sm:mt-6 max-w-3xl mb-5 px-2 sm:px-6"
            style={{ fontFamily: "Centaur" }}
          >
            <p className="text-pink-950 text-xl sm:text-base md:text-lg">
              Surprise Him/Her with a{" "}
              <span className="font-bold">
                Personalized <span></span>Love Song Album
              </span>{" "}
              featuring their name in the Songs
            </p>

            <p className="text-pink-950 text-xl sm:text-base md:text-lg mt-4">
              Imagine the look on their face when they hear their name in a
              beautiful song
            </p>
          </div>
        </div>

        {/* <div className="max-md:p-4">
          {[...Array(10)].map((_, i) => (
            <FloatingHeart key={i} delay={i * 0.5} />
          ))}
          <h1 className="text-2xl mt-14 max-md:mt-2 md:text-3xl text-pink-600 mb-2 font-dancing-script text-center">
            "We're taking orders until February 14th."
          </h1>
          <p className=" text-pink-600 mb-2 font-dancing-script text-center font-semibold">
            {" "}
          </p>
          <p className="text-base text-pink-600 mb-2 font-dancing-script text-center">
            Left
          </p>
          <div className="flex justify-center items-center space-x-2">
            <TimeUnit value={timeLeft.days} label="Days" />
            <div className="text-4xl text-pink-400 mb-8">:</div>
            <TimeUnit value={timeLeft.hours} label="Hours" />
            <div className="text-4xl text-pink-400 mb-8">:</div>
            <TimeUnit value={timeLeft.minutes} label="Minutes" />
            <div className="text-4xl text-pink-400 mb-8">:</div>
            <TimeUnit value={timeLeft.seconds} label="Seconds" />
          </div>
          <p className=" text-pink-600 mb-2 font-dancing-script text-center text-xl mt-4">To get a 50% Discount <br />on below prices</p>
        </div> */}

        <div className="md:mt-10">
          <div className="text-center ">
            <h1
              className="text-lg md:text-4xl mb-4  text-[#d74267] leading-relaxed md:leading-snug"
              style={{ fontFamily: "Centaur" }}
            >
              ORDER YOUR PERSONALIZED LOVE SONG
              <br />
              <span className="text-[#d74267] text-lg md:text-4xl mt-1">
                ALBUM OR SLIDESHOW VIDEO SONG NOW!
              </span>
            </h1>
          </div>

          <div className="grid grid-cols-2 gap-3 mb-10 px-4">
            {[
              {
                price: "1,999",
                label: "FOR A 10 SONGS",
                subtext: "PERSONALIZED LOVE ALBUM",
                onClick: () => handleLoveSongAlbum(1),
              },
              {
                price: "999",
                label: "FOR SLIDESHOW",
                subtext: " VIDEO SONG",
                onClick: () => handleLoveSongAlbum(2),
              },
            ].map((pkg, index) => (
              <div
                key={index}
                style={{
                  backgroundImage: `url(${priceBannerImg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="h-[140px] max-md:h-[100px] flex flex-col cursor-pointer  justify-center items-center rounded-xl px-0 text-center shadow-lg-[#d74368] text-[#ed295a] leading-tight"
                onClick={pkg.onClick}
              >
                <p className="font-extrabold mb-2 text-2xl md:text-2xl strock-text">
                  ₹ {pkg.price}
                </p>
                <p
                  className="font-extrabold text-xs md:text-xl px-1 strock-text-sub"
                  style={{ fontFamily: "Centaur" }}
                >
                  {pkg.label}
                </p>
                <p
                  className="font-extrabold mb-2 text-xs md:text-xl strock-text-sub"
                  style={{ fontFamily: "Centaur" }}
                >
                  {pkg.subtext}
                </p>
              </div>
            ))}
          </div>

          <div className="items-center"></div>
        </div>

        {/* <div className="mt-2 mb-6">
          <p className="text-center text-[#d74267] mt-2" >Get Personalized Love Album for <span className="line-through">Rs.1,999</span> Rs. 999 </p>
          <p className="text-center text-[#d74267]">Get Slideshow Video Song  for <span className="line-through">Rs.999</span> Rs. 499</p>
        </div> */}

        <div className="flex flex-col items-center justify-center mt-0 mb-10">
          <p
            className="text-[#9f7e2a] italic  px-10 mb-6 text-semo font-semibold md:text-2xl text-center"
            style={{ fontFamily: "Centaur" }}
          >
            Once your order is placed, the link to your album will be digitally
            delivered to you via email or WhatsApp within 12 Hours
          </p>
          <h1 className="text-xl font-semibold text-[#d74267] mb-4  text-center md:text-2xl">
            ❤️ See how Hrithik surprised Priya ❤️
          </h1>
          <div>
            <div
              onClick={() =>
                handleVideoModalOpen(
                  "https://imas-general.s3.eu-central-003.backblazeb2.com/2025-02-07+at+6.36.56.mp4"
                )
              }
              className="video-card relative rounded-xl shadow-xl border border-gray-200 overflow-hidden cursor-pointer hover:shadow-2xl transition-shadow duration-300"
            >
              <img
                src="https://imas-general.s3.eu-central-003.backblazeb2.com/2.png"
                alt="Singing Selfie Tutorial"
                className="max-md:w-64 max-md:h-40 w-80 h-64 object-cover "
              />

              <div className="absolute bg-[#ca3f5d] px-2 py-2 rounded-full  top-[40%] left-[40%]">
                <span className="text-white text-xl font-semibold">
                  <FaPlay className="text-2xl ml-1" />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center mt-8 ">
          <p className=" w-full bg-[#f68690]  text-white py-2 px-1 transition">
            <p className="p-0 py-2 px-8 text-center text-white md:text-xl text-xl font-semibold">
              We can only deliver a limited number of orders for Valentine's.{" "}
              <br /> Order Now so you don't miss out
            </p>
          </p>
        </div>

        <p
          className="text-xl text-center md:text-4xl text-pink-600 mt-4 px-4"
          style={{ fontFamily: "Centaur" }}
        >
          We deliver your Personalized Love Album in your own Spotify type feel
        </p>

        <p
          className="text-xl text-center md:text-4xl text-pink-600 mt-2"
          style={{ fontFamily: "Centaur" }}
        >
          ❤️ See some examples below ❤️
        </p>
        <div className=" mt-5 md:mt-10">
          {/* <h2
            className="text-center text-base md:text-3xl  text-[#f14ca7]"
            style={{ fontFamily: "Centaur" }}
          >
            Listen to How Some of Our Customers Created
            <p
              className=" text-2xl md:text-4xl text-pink-600 "
              style={{ fontFamily: "Centaur" }}
            >
              Personalized Love Song Albums
            </p>
          </h2> */}
          {/* <p
            className=" text-[#d74267]   text-sm text-center font-semibold mt-2  md:text-xl "
            style={{ fontFamily: "Centaur" }}
          >
            ₹ 1,999 for Personalized Love Song Album
          </p> */}

          <div className="relative px-2 -mt-4">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-5 relative z-10 p-4 rounded-lg">
              {[
                {
                  img: Img1,
                  text: "Happy Valentines Arjun",
                  icon: <FaRegPlayCircle className="fill-white text-2xl" />,
                  link: "/valentines/202401",
                },
                {
                  img: Img2,
                  text: "Happy Valentines Priya",
                  icon: <FaRegPlayCircle className="fill-white text-2xl" />,
                  link: "/valentines/202402",
                },
                {
                  img: Img3,
                  text: "Happy Valentines Avani",
                  icon: <FaRegPlayCircle className="fill-white text-2xl" />,
                  link: "/valentines/202403",
                },
                {
                  img: Img4,
                  text: "Happy Valentines Vihhan",
                  icon: <FaRegPlayCircle className="fill-white text-2xl" />,
                  link: "/valentines/202404",
                },
              ].map((item, index) => (
                <div key={index} className="relative group overflow-hidden ">
                  <div className="w-full h-44 md:h-60 relative">
                    <img
                      src={item.img}
                      alt={item.text}
                      className="w-full h-full object-cover  rounded-2xl border-4 border-white"
                    />
                  </div>
                  <button
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black p-2 rounded-full "
                    onClick={() => navigate(item.link)}
                  >
                    {item.icon}
                  </button>
                  <div
                    className="text-center md:text-xl px-2 text-[#db2777] font-semibold "
                    style={{ fontFamily: "Centaur" }}
                  >
                    {item.text}
                  </div>
                </div>
              ))}
            </div>
            <p className=" text-[#d74267] text-sm text-center mb-4  mt-2 md:text-xl font-bold">
              ₹ 1,999 for Personalized Love Album
            </p>

            <img
              src={heartSideImg}
              alt="Right Side Illustration"
              className="absolute top-1/2 right-0 transform -translate-y-1/2 w-[100px] h-24 z-0 blur-sm md:hidden"
            />
          </div>

          {/* <div className="max-md:p-0 p-4 mt-4 ">
            <div
              className="grid grid-cols-1 md:grid-cols-6 gap-7 items-center"
              style={{
                background:
                  "linear-gradient(to bottom, #fee5ea, #fee5ea, #fff5f7)",
              }}
            >
              <div className="col-span-2 max-md:col-span-4 flex justify-center ">
                <img
                  src={img8}
                  alt="Bonus Offer"
                  className="rounded-lg shadow-lg  bg-contain mt-2"
                  onClick={() =>
                    handleVideoModalOpen(
                      "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/valentine-song-image1.mp4"
                    )
                  }
                />
              </div>
              <div className="col-span-4 text-left text-gray-800 px-2 my-2">
                <h2
                  className="text-xl md:text-4xl mb-1 text-[#a18a27] px-2 text-center md:text-left"
                  style={{ fontFamily: "Centaur" }}
                >
                  Lucky You! We have a{" "}
                  <span className="text-[#a18a27] font-bold text-2xl">
                    BONUS OFFER
                  </span>{" "}
                  for you!
                </h2>
                <p className="text-[#c98484] mb-1 text-sm md:text-xl  text-center md:text-left">
                  We’re making your moments extra special with <br />
                  <span className="">PERSONALIZED IMAGE VIDEO SONG.</span>
                </p>
                <p className="text-[#c98484] mb-1 text-sm md:text-xl text-center md:text-left ">
                  Upload a picture with your partner, and we’ll create an image
                  video featuring one of your personalized songs!
                </p>
              </div>
            </div>
          </div> */}
          <div className="text-center mt-4 ">
            <p
              className="bg-[#f68690] w-full text-white py-6 px-10   transition md:text-2xl border "
              style={{ fontFamily: "Centaur" }}
            ></p>
          </div>
        </div>
        {/* efc6d1 */}

        <div className="p-4 mt-4">
          <div className="flex  md:flex-row md:justify-between items-center gap-7 max-md:gap-3">
            {/* Content Section */}
            <div className="text-left text-gray-800 px-2 max-md:px-0 my-1 flex-1">
              <h2
                className="text-xl md:text-4xl mb-2 text-[#a18a27] px-2 md:px-0 text-center md:text-left"
                style={{ fontFamily: "Centaur" }}
              >
                SLIDESHOW VIDEO SONG
              </h2>
              <p className="text-[#c98484] mb-3 text-sm md:text-xl ">
                Share your favorite photos, and we'll create a slideshow paired
                with your personalized song. 3 to 10 Photos required
              </p>
              <p
                className="text-[#d74267] text-xs font-bold md:text-xl mt-4 flex items-center gap-0"
                style={{ fontFamily: "Centaur" }}
              >
                ₹ 999 for Slideshow video song
              </p>
            </div>

            {/* Image Section */}
            <div className="flex justify-center flex-1">
              <img
                src={slideShowImg}
                alt="Bonus Offer"
                className="rounded-lg shadow-lg bg-contain"
                onClick={() =>
                  handleVideoModalOpen(
                    "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/riya-valentines-song1.mp4"
                  )
                }
              />
            </div>
          </div>
        </div>

        <div className="mt-9  bg-gradient-to-b from-[#fff6f8] to-[#f8bbd0b3] ">
          <div className="text-center ">
            <h1
              className="text-lg md:text-4xl mb-4  text-[#d74267] leading-relaxed md:leading-snug"
              style={{ fontFamily: "Centaur" }}
            >
              ORDER YOUR PERSONALIZED LOVE SONG
              <br />
              <span className="text-[#d74267] text-lg md:text-4xl mt-1">
                ALBUM OR SLIDESHOW VIDEO SONG NOW!
              </span>
            </h1>
          </div>

          <div className="grid grid-cols-2 gap-3 mb-8 px-4">
            {[
              {
                price: "1,999",
                label: "FOR A 10 SONGS",
                subtext: "PERSONALIZED LOVE ALBUM",
                onClick: () => handleLoveSongAlbum(1),
              },
              {
                price: "999",
                label: "FOR SLIDESHOW",
                subtext: " VIDEO SONG",
                onClick: () => handleLoveSongAlbum(2),
              },
            ].map((pkg, index) => (
              <div
                key={index}
                style={{
                  backgroundImage: `url(${priceBannerImg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="h-[140px] max-md:h-[100px] flex flex-col cursor-pointer  justify-center items-center rounded-xl px-0 text-center shadow-lg-[#d74368] text-[#ed295a] leading-tight"
                onClick={pkg.onClick}
              >
                <p className="font-extrabold mb-2 text-2xl md:text-2xl strock-text">
                  ₹ {pkg.price}
                </p>
                <p
                  className="font-extrabold  text-xs md:text-xl px-1 strock-text-sub"
                  style={{ fontFamily: "Centaur" }}
                >
                  {pkg.label}
                </p>
                <p
                  className="font-extrabold mb-2 text-xs md:text-xl strock-text-sub"
                  style={{ fontFamily: "Centaur" }}
                >
                  {pkg.subtext}
                </p>
              </div>
            ))}
          </div>

          <div className=" items-center text-center mb-8">
            <div>
              {/* <div className="mt-2 mb-6">
                <p className="text-center font-semibold text-lg">Use Code : VAL50 for a 50% Discount</p>
                <p className="text-center text-[#d74267] mt-2" >Get Personalized Love Album for <span className="line-through">Rs.1,999</span> Rs. 999 </p>
                <p className="text-center text-[#d74267]">Get Slideshow Video Song  for <span className="line-through">Rs.999</span> Rs. 499</p>
              </div> */}
              <button
                className="bg-[#eee6bf] text-[#d74267] text-lg md:text-xl py-1  px-4 rounded-full shadow-lg hover:shadow-xl transition-shadow border border-white "
                style={{ fontFamily: "Centaur" }}
                onClick={handleOrderNow}
              >
                ORDER NOW
              </button>
              <p
                className="text-[#9f7e2a] italic mt-3 py-4 px-10 text-semo font-semibold md:text-2xl"
                style={{ fontFamily: "Centaur" }}
              >
                Once your order is placed, the link to your album will be
                digitally delivered to you via email or WhatsApp within 12 Hours
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center mt-6">
          <h1
            className="text-xl font-semibold text-[#d74267] mb-6 px-8  text-center md:text-2xl"
            style={{ fontFamily: "Centaur" }}
          >
            Create a Personalized Love Song Album for Your Long-Distance
            Relationship
          </h1>
          <div>
            <div
              onClick={() =>
                handleVideoModalOpen(
                  "https://imas-general.s3.eu-central-003.backblazeb2.com/valentines_video.mp4"
                )
              }
              className="video-card relative rounded-xl shadow-xl border border-gray-200 overflow-hidden cursor-pointer hover:shadow-2xl transition-shadow duration-300"
            >
              <img
                src="https://imas-general.s3.eu-central-003.backblazeb2.com/valentines_theme.png"
                alt="Singing Selfie Tutorial"
                className="max-md:w-64 max-md:h-40 w-80 h-64 object-cover "
              />

              <div className="absolute bg-[#ca3f5d] px-2 py-2 rounded-full  top-[40%] left-[40%]">
                <span className="text-white text-xl font-semibold">
                  <FaPlay className="text-2xl ml-1" />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className=" mt-6 md:mt-6">
          <div className="mb-3">
            <h2
              className="items-center  font-semibold text-center text-[#d74267] text-4xl max-md:text-xl"
              style={{ fontFamily: "Centaur" }}
            >
              ❤️ Ideas to use your Personalised Songs ❤️
            </h2>
          </div>

          <div className="grid grid-cols-3 md:grid-cols-3 gap-3 md:mt-5 px-2">
            {[
              {
                src: img5,
                alt: "Candlelight dinner",
                text: "Play the song during a romantic candlelight dinner",
              },
              {
                src: img6,
                alt: "Romantic drive",
                text: "Play the song on a scenic romantic drive",
              },
              {
                src: img7,
                alt: "Proposal moment",
                text: "Use the song as part of your proposal moment",
              },
              {
                src: img10,
                alt: "Heartfelt handwritten note",
                text: "Pair the song with a heartfelt handwritten note",
              },
              {
                src: img11,
                alt: "Alarm to start",
                text: "Use the song as an alarm to start their day with love",
              },
              {
                src: img12,
                alt: "Valentine's celebration",
                text: "Play the song at a Valentine’s celebration",
              },
            ].map((item, index) => (
              <div className="text-center" key={index}>
                <div className="relative w-full h-36 md:h-64 lg:h-80 rounded-xl overflow-hidden">
                  <img
                    src={item.src.replace(".jpg", ".webp")}
                    alt={item.alt}
                    loading="lazy"
                    className="w-full h-full object-cover object-center"
                  />
                </div>
                <p className="mt-4 text-[#d74267] text-xs md:text-xl leading-tight">
                  {item.text}
                </p>
              </div>
            ))}
          </div>

          <div className="text-center mt-8">
            <div className=" items-center text-center ">
              <div>
                <button
                  className="bg-[#eee6bf] text-[#d74267] text-lg md:text-xl py-1  px-4 rounded-full shadow-lg hover:shadow-xl transition-shadow border border-white "
                  style={{ fontFamily: "Centaur" }}
                  onClick={handleOrderNow}
                >
                  ORDER NOW
                </button>
                <p
                  className="text-[#a9933d] italic mt-4 text-base md:text-2xl"
                  style={{ fontFamily: "Centaur" }}
                >
                  Once your order is placed, the link to your album will be
                  <br />
                  digitally delivered via email or WhatsApp within 12 Hours
                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <section className=" text-white  px-6">
            <h2
              className="text-center text-[#d74267]  font-bold text-xl md:text-2xl max-md:mb-2 mt-6 max-md:mt-2"
              style={{ fontFamily: "Centaur" }}
            >
              Happy Customers
            </h2>
            <div className="w-full mx-auto mt-2">
              <Swiper
                loop={true}
                spaceBetween={30}
                slidesPerView={1}
                breakpoints={{
                  768: { slidesPerView: 2 },
                  1024: { slidesPerView: 3 },
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: true,
                }}
                modules={[Autoplay]}
                className="pb-10"
              >
                {testimonials.map((testimonial, index) => (
                  <SwiperSlide key={index}>
                    <div className="px-2 py-3 bg-[#f5eee1] rounded-xl shadow-md transition-transform transform scale-x-100">
                      <p className="text-base  italic mt-5 text-[#bb1c5c] mb-4">
                        "{testimonial.review}"
                      </p>
                      <p className="text-right text-[#776c4e] italic font-16 font-medium">
                        - {testimonial.name}
                      </p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </section>
        </div>

        {/* <div className="flex flex-col items-center justify-center mt-0 mb-8">
          <h1
            className="text-xl font-semibold text-[#d74267] mb-3  text-center md:text-3xl"
            style={{ fontFamily: "Centaur" }}
          >
            Step by Step Guide on How to Order?
          </h1>
          <div>
            <div
              onClick={() =>
                handleVideoModalOpen(
                  "https://imas-general.s3.eu-central-003.backblazeb2.com/445664674.mp4"
                )
              }
              className="video-card relative rounded-xl shadow-xl border border-gray-200 overflow-hidden cursor-pointer hover:shadow-2xl transition-shadow duration-300"
            >
              <img
                src="https://imas-general.s3.eu-central-003.backblazeb2.com/1.png"
                alt="Singing Selfie Tutorial"
                className="max-md:w-64 max-md:h-40 w-80 h-64  "
              />

              <div className="absolute bg-[#ca3f5d] px-2 py-2 rounded-full  top-[40%] left-[40%]">
                <span className="text-white text-xl font-semibold">
                  <FaPlay className="text-2xl ml-1" />
                </span>
              </div>
            </div>
          </div>
        </div> */}

        <div className="mt-5">
          <div className="grid grid-cols-6  items-center">
            <div className="col-span-4 text-center md:text-left text-gray-800 px-4">
              <h1
                className="text-base md:text-4xl  mb-4 text-[#a18a27]"
                style={{ fontFamily: "Centaur" }}
              >
                Surprise Your Valentine with <br />
                <span className="font-bold">
                  a Personalized Love Song Album
                </span>
              </h1>

              <ul className=" text-[#c98484] mb-4 text-sm md:text-xl">
                <li className="mb-2 flex  items-start">
                  <img
                    src={heartIcon}
                    alt="Heart Icon"
                    className="w-4 h-3 md:w-6 md:h-4 md:mr-1 mt-[4.5px]"
                  />
                  <span className="text-left ml-1">
                    Digital delivery via email or WhatsApp within 12 Hours
                  </span>
                </li>
                <li className="mb-2 flex  items-start">
                  <img
                    src={heartIcon}
                    alt="Heart Icon"
                    className="w-4 h-3 md:w-6 md:h-4 md:mr-1 mt-[4.5px]"
                  />
                  <span className="text-left ml-1">
                    Features your partner's name
                  </span>
                </li>
                <li className="mb-2 flex  items-start">
                  <img
                    src={heartIcon}
                    alt="Heart Icon"
                    className="w-4 h-3 md:w-6 md:h-4 md:mr-1 mt-[4.5px]"
                  />
                  <span className="text-left ml-1">
                    Celebrate your relationship
                  </span>
                </li>
              </ul>
              <p className=" text-[#d74267]  font-bold py-1 px-1  mb-4 md:text-2xl ">
                ₹ 1,999 for a 10-song <br /> Personalized Love Song Album
              </p>
              <div className="bg-[#eee6bf] md:text-xl font-semibold text-sm py-1 md:py-3 px-3   text-pink-700   rounded-xl md:w-3/4">
                <p
                  className="text-[#d74267]  md:text-2xl text-center cursor-pointer"
                  style={{ fontFamily: "Centaur" }}
                  onClick={() => handleLoveSongAlbum(1)}
                >
                  GET YOUR PERSONALIZED LOVE SONG ALBUM
                </p>
              </div>
            </div>

            <div className="col-span-2 flex justify-center relative">
              <img
                src={sideImg1}
                alt="Background"
                className="absolute top-[-1rem] right-0   z-0"
              />
              <img
                src={mobileImg}
                alt="Phone Mockup"
                className="relative w-64 h-64 md:w-72 md:h-[28rem] z-10"
                onClick={() => navigate("/valentines/202404")}
              />
            </div>
          </div>
        </div>

        <div className="mt-5">
          <div className="grid grid-cols-6  items-center">
            <div className="col-span-2 flex justify-center px-1 ">
              <img
                src={mobileImg1}
                alt="Phone Mockup"
                className=" w-64 h-64 md:w-72 md:h-[28rem]"
                onClick={() =>
                  handleVideoModalOpen(
                    "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/riya-valentines-song1.mp4"
                  )
                }
              />
            </div>
            <div className="col-span-4 text-center md:text-left text-gray-800 px-4">
              <h1
                className="text-base md:text-4xl  mb-4 text-[#a18a27] text-start"
                style={{ fontFamily: "Centaur" }}
              >
                Relive Your Love Story with a <br />
                <span className="font-bold">
                  Stunning Slideshow of Memories
                </span>
              </h1>

              <ul className=" text-[#c98484] mb-4 text-sm md:text-xl">
                <li className="mb-2 flex  items-start">
                  <img
                    src={heartIcon}
                    alt="Heart Icon"
                    className="w-4 h-3 md:w-6 md:h-4 md:mr-1 mt-[4.5px]"
                  />
                  <span className="text-left ml-1">
                    Digital delivery via email or WhatsApp within 12 Hours
                  </span>
                </li>
                <li className="mb-2 flex  items-start">
                  <img
                    src={heartIcon}
                    alt="Heart Icon"
                    className="w-4 h-3 md:w-6 md:h-4 md:mr-1 mt-[4.5px]"
                  />
                  <span className="text-left ml-1">
                    Combined photos with a beautiful Personalized song
                  </span>
                </li>
                <li className="mb-2 flex  items-start">
                  <img
                    src={heartIcon}
                    alt="Heart Icon"
                    className="w-4 h-3 md:w-6 md:h-4 md:mr-1 mt-[4.5px]"
                  />
                  <span className="text-left ml-1">
                    A heart-touching visual experience
                  </span>
                </li>
              </ul>
              <p className=" text-[#d74267]  py-1 px-1  mb-4 md:text-2xl font-bold">
                ₹ 999 for Slideshow video song
              </p>
              <div className="bg-[#eee6bf] md:text-xl  py-1 md:py-3 px-6 text-sm  text-pink-700  font-semibold rounded-xl md:w-3/4">
                <p
                  className="text-[#d74267]  md:text-2xl text-center cursor-pointer"
                  style={{ fontFamily: "Centaur" }}
                  onClick={() => handleSlideShowAlbum(2)}
                >
                  GET YOUR SLIDESHOW VIDEO SONG ALBUM
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="relative px-5 mb-4 md:mt-12 mt-10">
          <div className="bg-[#f5eee0] rounded-xl  p-4  max-md:p-2 max-md:mt-2 relative z-10">
            <h2 className="text-2xl md:text-4xl text-center text-[#d74267] font-serif mb-10 max-md:mb-6">
              ABOUT THE MAKERS
            </h2>
            <div className="text-[#746c46] space-y-4 mb-8 text-base md:text-xl flex flex-col items-center justify-center">
              <p>
                Digikore Studios is an NSE-listed firm & a renowned name in the
                entertainment industry
              </p>
              <ul className="space-y-2 text-base md:text-xl">
                <li>
                  •<span className="font-semibold"> 24 years</span> of
                  experience in the international media sector
                </li>
                <li>
                  •<span className="font-semibold"> 14 years</span> specializing
                  in film and television
                </li>
                <li>
                  • Delivering{" "}
                  <span className="font-semibold">250+ Hollywood films</span>{" "}
                  and TV series
                </li>
              </ul>
            </div>
            <hr className="bg-white h-1 rounded-full my-2 " />
            <p className="text-center text-[#d74267] font-semibold mt-4 md:text-xl text-sm">
              We work with globally acclaimed companies
            </p>
            <div className="flex justify-center items-center gap-1 mt-4 w-full px-1">
              <img src={marvelImg} alt="Marvel Studios" className="h-6" />
              <img src={netflixImg} alt="Netflix" className="h-6" />
              <img src={primeImg} alt="Prime Video" className="h-6" />
            </div>
          </div>

          <img
            src={sideImg1}
            alt="Right Side Illustration"
            className="absolute top-5 right-0 transform -translate-y-1/2 w-48 h-auto  z-20"
          />
        </div>

        <div className="flex flex-col items-center gap-4 p-6 mt-4">
          <h2 className="text-l  text-[#590812] font-serif">
            Let’s Share Love with Your Friends!
          </h2>
          <button
            onClick={() => handleInviteFriends("whatsapp")}
            className="px-6 flex py-2 strock-text-sub valentines-share-btn-pink text-white rounded-full shadow-md transition duration-300"
          >
            <FaWhatsapp className="mr-2 text-green-500" fontSize={22} /> Share
            on WhatsApp
          </button>
        </div>

        <div className="flex flex-col items-center justify-center bg-gray-100 px-4">
          <h1 className="text-xl  text-[#590812] font-serif  text-center mb-6">
            Get 20% OFF! Sign up with your email to unlock your exclusive
            discount.
          </h1>

          <form
            className="w-full max-w-md mb-6"
            onSubmit={handleSubmit(handleEmailInvite)}
          >
            <div className="flex items-center border border-gray-300 rounded-lg overflow-hidden">
              <input
                type="email"
                placeholder="Enter your email"
                className="w-full px-4 py-2 focus:outline-none"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Please enter a valid email address",
                  },
                })}
              />
              <button
                type="submit"
                className="px-6 py-2 bg-[#efe6bf]  text-[#d74267] font-semibold transition-colors"
              >
                {loading ? "Sending..." : "Send"}
              </button>
            </div>
            {errors.email && (
              <span className="text-red-500 text-sm mt-1 ml-1">
                {errors.email.message}
              </span>
            )}
          </form>
        </div>

        <div className="mt-2">
          <div className="bg-[#f68690] text-center py-6 ">
            <h2
              className="text-xl md:text-4xl  text-white px-2"
              style={{ fontFamily: "Centaur" }}
            >
              To Love, Laughter & Happily Ever Afters!
            </h2>
            <p className="text-white mt-4 px-8  max-md:text-base text-xl line-leading">
              Imagine the look on their face when they hear their name in a
              beautiful Song. It’s more than just a GIFT—it’s a deep, emotional
              connection
            </p>
            <p className="text-white italic md:text-xl mt-4">
              Don’t Wait—Limited Time Offer!
            </p>
          </div>

          <div className="mt-2 flex justify-end items-center ">
            <div className="flex gap-4 items-center">
              <p
                className="text-pink-500 text-sm text-right"
                style={{ fontFamily: "Centaur" }}
              >
                BRAND PARTNERS
              </p>
              <img src={fnpImg} alt="FNP Logo" className="h-10" />
              <img src={tripImg} alt="EaseMyTrip Logo" className="h-12 " />
              <img src={imgBrand} alt="EaseMyTrip Logo" className="h-10 " />
            </div>
          </div>

          <div className=" block justify-between items-center   ">
            <p
              className="text-white text-base  text-center bg-[#f68690]  "
              style={{ fontFamily: "Centaur" }}
            >
              A Product by Digikore Studios Limited
            </p>

            <p
              className="text-[#590812] text-center  mt-2"
              style={{ fontFamily: "Centaur" }}
            >
              Lalwani Triumph, Viman Nagar, Pune - 411014
              <p className=" underline">
                <a href="mailto:support@imadeasong.com">
                  Email: support@imadeasong.com
                </a>
              </p>
            </p>

            <div
              className="flex  md:justify-end justify-center space-x-5 mt-2 px-2 mb-4"
              style={{ fontFamily: "Centaur" }}
            >
              <p
                className="text-[#590812] text-xs md:text-base cursor-pointer underline"
                onClick={() =>
                  openModalWithComponent(<ValentineTermAndCondition />)
                }
              >
                Terms and Services
              </p>
              <p
                className="text-[#590812] text-xs md:text-base cursor-pointer underline"
                onClick={() =>
                  openModalWithComponent(<ValentinePrivacyPolicy />)
                }
              >
                Privacy and Policy
              </p>
              <p
                className="text-[#590812] text-xs md:text-base cursor-pointer underline"
                onClick={() =>
                  openModalWithComponent(<ValentineCancellationPolicy />)
                }
              >
                Cancellation and Refund Policy
              </p>
            </div>
          </div>
          <NotificationPopup />
        </div>
      </div>

      {isModalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative  rounded-lg p-0 shadow-lg max-w-sm w-full">
            {/* Close Icon */}
            <button
              onClick={closeModal}
              className="absolute top-4 right-5 font-bold text-4xl text-black"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            {/* Image Notification */}
            <div className="flex items-center justify-center">
              <img
                // src="https://imas-general.s3.eu-central-003.backblazeb2.com/offerbanner.jpeg" // Replace with your image URL
                src="https://imas-valentine.s3.eu-central-003.backblazeb2.com/general-images/partner_banner.jpeg"
                alt="Modal Content"
                className="rounded-3xl w-full"
              />
            </div>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-pink-100 bg-opacity-90">
          <div className="relative bg-white w-96 rounded-lg shadow-lg p-6 overflow-hidden z-10">
            {/* Heart decorations */}
            <div className="absolute -top-16 -left-16 w-40 h-40 bg-pink-400 rounded-full transform rotate-45 z-0 opacity-60"></div>
            <div className="absolute -bottom-16 -right-16 w-48 h-48 bg-pink-500 rounded-full transform rotate-45 z-0 opacity-60"></div>

            {/* Modal Content */}
            <div className="relative z-10">
              <h2 className="text-2xl font-bold text-white mb-4 text-center">
                What do you want to Gift Your Valentine?
              </h2>
              <p className="text-white text-center text-base font-bold mb-6">
                Celebrate this Valentine’s Day by selecting either a Love Song
                Album or a Slideshow Video Song
              </p>
              <div className="flex justify-between space-x-4">
                <button
                  onClick={() => handleSelection(1)}
                  className="flex-1 px-4 text-base py-2 bg-cyan-800 text-white font-bold rounded-md hover:bg-blue-500 transition shadow-md"
                >
                  Love Song Album
                  <br />
                  <div className="px-4">
                    <hr className="mt-2 mb-2 bg-pink-200 h-0.5 border-0" />
                  </div>
                  <span className="text-2xl font-bold">₹ 1999</span>
                </button>
                <button
                  onClick={() => handleSelection(2)}
                  className="flex-1 px-4 text-base py-2 bg-cyan-800 text-white font-bold rounded-md hover:bg-blue-500 transition shadow-md"
                >
                  Slideshow Video Song
                  <br />
                  <div className="px-4">
                    <hr className="mt-2 mb-2 bg-pink-200 h-0.5 border-0" />
                  </div>
                  <span className="text-2xl font-bold">₹ 999</span>
                </button>
              </div>
              <button
                onClick={() => setIsModalOpen(false)}
                className="mt-6 text-sm text-gray-300 underline hover:text-pink-500 transition block mx-auto"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {isVideoModalOpen && (
        <div className="fixed inset-0 bg-black  flex justify-center items-center z-50">
          {/* Close Button */}
          <button
            className="absolute top-4 right-6 text-white font-bold text-3xl z-50"
            onClick={handleVideoModalClose}
          >
            <IoCloseCircleOutline />
          </button>

          <div className="w-full h-full flex justify-center items-center pointer-events-none">
            <video
              className="w-full h-full max-w-4xl max-h-full rounded-lg pointer-events-auto"
              controls
              autoPlay
              playsInline
            >
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag
            </video>
          </div>
        </div>
      )}

      {/* HERE IS MAGIC */}
      {/* */}

      {/* pdf open modal pop up */}
      {isPdfModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-95  flex justify-center items-center z-[999] shadow-md">
          <div
            className=" rounded-lg px-2 relative max-w-7xl"
            style={{ height: "100%" }}
          >
            <button
              className="absolute top-1 right-2 text-white text-xl hover:text-black "
              onClick={closepdfModal}
            >
              ✖
            </button>
            <div className="mt-6 overflow-auto h-[900px]">
              {/* Render the dynamic component */}
              {currentComponent}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ValentinePage;
