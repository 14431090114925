import React, { useEffect, useState } from "react";
import { getRandomNotification } from "../../src/utils/valentinesNotificationSet.js";
import heartImg from "../assets/valentineImages/like-removebg-preview.png";
import slidshowImg from "../assets/valentineImages/presentation-removebg-preview.png";

const NotificationPopup = () => {
  const [visible, setVisible] = useState(false);
  const [currentNotification, setCurrentNotification] = useState("");
  const [currentIcon, setCurrentIcon] = useState("heart");

  useEffect(() => {
    const delays = [4000, 8000, 13000];
    let index = 0;
    let intervalId;

    const showNotification = () => {
      const { notification, icon } = getRandomNotification();
      setCurrentNotification(notification);
      setCurrentIcon(icon);
      setVisible(true);

      setTimeout(() => setVisible(false), 6000);

      index = (index + 1) % delays.length;

      intervalId = setTimeout(showNotification, delays[index]);
    };
    intervalId = setTimeout(showNotification, delays[index]);
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div
      className={`popup fixed bottom-4 right-3 z-50 transition-all flex items-center duration-800 ease-in-out transform gap-2 ${
        visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-5"
      }`}
      style={{ minWidth: "280px", maxWidth: "380px" }}
    >
      {/* Icon container */}
      <div className="flex items-center justify-center w-12 h-10 p-0 rounded-full">
        {currentIcon === "heart" ? (
          <img
            src={heartImg}
            alt="heartImg"
            className="h-6 w-6 fill-pink-700 "
          />
        ) : (
          <img
            src={slidshowImg}
            alt="heartImg"
            className="h-6 w-6 fill-current text-blue-500"
          />
        )}
      </div>

      {/* Notification message */}
      <div className=" popup-bg px-3 py-2 leading-tight shadow-lg ">
        <span className="text-sm leading-tight">{currentNotification}</span>
      </div>
    </div>
  );
};

export default NotificationPopup;
