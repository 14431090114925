import React from 'react'

function ValentineCancellationPolicy() {
  return (
    <div className="bg-[#fff6f8]  min-h-screen px-4 rounded-md ">
      <div className="max-w-6xl mx-auto py-6 max-md:mt-4 ">
        <h1 className="text-2xl md:text-4xl font-bold text-center text-black mb-4">
          CANCELLATION AND REFUND POLICY
        </h1>
        <div>
          <p className="text-md text-gray-800 mb-8">
            At <span className="font-bold">Digikore Studios Limited.</span>,
            we strive to provide the best experience with our visual effects
            and digital creative services. We understand that situations may
            arise where you might need to cancel a service or request a
            refund. Below is our policy on cancellations and refunds:
          </p>
        </div>
        <div>
          <h6 className="text-2xl  md:text-2xl  text-start font-bold text-black mb-6">
            Refunds
          </h6>
          <p className="text-md text-gray-800 mb-8">
            <ol>
              <li>
                <span className="font-bold">• How to Request a Refund:</span>{" "}
                To request a refund, simply send us a written notice
                explaining your reason for dissatisfaction.{" "}
              </li>
              <li>
                <span className="font-bold">• Refund Processing</span>: We
                will review your request, and if it meets our refund criteria,
                we’ll process your refund within 7 working days.{" "}
              </li>
            </ol>
          </p>
        </div>
        <div>
          <h6 className="text-2xl md:text-2xl  text-start font-bold text-black mb-6">
            Cancellation
          </h6>
          <p className="text-md text-gray-800 mb-8 ">
            <span className="font-bold">•
              Cancelling a Service:</span>  You can cancel your service at any time.
            <ol>
              <li>
                <span className="font-bold">• Before Completion:</span> If you
                cancel before we’ve completed the agreed work, you will be
                charged for the work done up until that point.
              </li>
              <li>
                <span className="font-bold">• After Completion:</span> If the work is already completed, no additional charges will apply upon cancellation.
              </li>
            </ol>
          </p>
        </div>
        <div>
          <h6 className="text-2xl md:text-2xl  text-start font-bold text-black mb-6">
            Additional Notes
          </h6>
          <p className="text-md text-gray-800 mb-32 md:mb-0">
            <ol>
              <li>
                <span className="font-bold">• Third-Party Charges:</span>{" "}
                Please note that any third-party fees or charges (e.g.,
                payment processing fees) are{" "}
                <span className="font-bold">non-refundable</span>.{" "}
              </li>
              <li>
                If you have any questions or need further assistance, feel
                free to reach out to us at  {" "}
                <a
                  className="text-blue-600"
                  href="mailto: support@imadeasong.com"
                >
                  support@imadeasong.com.
                </a>{" "}

              </li>
            </ol>
          </p>
        </div>
      </div>
    </div>
  )
}

export default ValentineCancellationPolicy
